import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import AddDevice from "./addDevices";
import deleteIcon from "../buttonIcons/folder-delete-icon.png";
import updateIcon from "../buttonIcons/folder-refresh-icon.png";
import historyIcon from "../buttonIcons/history-line-icon.png";
import assignIcon from "../buttonIcons/male-add-icon.png";
import playIcon from "../buttonIcons/play-round-icon.png";
import barcodeIcon from "../buttonIcons/barcode-scan-icon.png";
import qrIcon from "../buttonIcons/qr-code-scan-icon.png";
import { publicIpv4 } from "public-ip";
const config = require("../Settings.json");

const GetDevices = () => {
    const isAdmin = sessionStorage.getItem("getCompany");
    //const [isAssigned, setAssignStat] = useState("Not Assigned");
    const [data, setData] = useState([]);
    const [dataSet, setDataSet] = useState(new Set());
    const [ipv4Add, setIP] = useState("");
    let finalDataSet = [];

    function getList(e){
        axios.post(config.DEV_URL + "/GetDevicesList", {
            comp: e//sessionStorage.getItem("getCompany")
        }).then(res=>{
            if(res.data == "No Records Found"){
                setData(["null"]);
            }
            else{
            setData(res.data);
            if(e == sessionStorage.getItem("getCompany")){
                setDataSet(new Set(res.data.map(i => i.CompanyDevice)));
                }
            }
        }).catch(err => {
            console.log(err);
        })
        return data;
    }

    async function getIP(){
        await publicIpv4().then(res => {
            setIP(res);
        })
    }

    useEffect(()=>{
        getList(sessionStorage.getItem("getCompany"));
        getIP();
    }, [])

    if(dataSet.size > 0){
        dataSet.forEach(e => {
             finalDataSet.push(e);
        });
    }

    const handleDelete = async (e) =>{
        if(e.target.value === " "){
        window.alert("There are no records to delete");
        }
        else{
            if(window.confirm("Do you want to delete Device ID: " + e.target.value) == true){
                await getDeletedData(e.target.value).then(delRes => {
                        axios.put(config.DEV_URL + "/trailingChanges", {
                        userName: sessionStorage.getItem("user?name"),
                        actionTaken: "Deleting Device",
                        actionLoc: "Devices Page",
                        changeMade: JSON.stringify(delRes),
                        IPv4Addy: ipv4Add
                    }).catch(err => {
                        console.log(err);
                    })
                });

                axios.post(config.DEV_URL +"/DeleteDevice", {
                    devID: e.target.value
                }).then(res => {
                    if(res == null){
                        console.log("Invalid ID");
                    }
                    else{
                        window.location.reload(); 
                        console.log(res);
                    }
                }).catch(err => {
                    console.log(err);
                })
            }
       }
    }

    const addDevice = () => {
            window.location = "/Add-Devices";
    }
    const getSearchVal = (e) => {
        searchList(e);
        e.preventDefault();
    }
    const getDeletedData = async (e) => {
        let data = {}
        await axios.post(config.DEV_URL + "/GetDevicesByID", {
            devID: e,
            comp: sessionStorage.getItem("getCompany")
        }).then(res => {
            data = res.data;
        }).catch(err => {
            console.log(err);
        })

        return data;
    }

    const filterData = (e) =>{
        axios.post(config.DEV_URL + "/getDeviceFilter", {
            Company:sessionStorage.getItem("getCompany"),
            Filter: e.target.value
        }).then(res =>{
            setData(res.data);
            console.log(res.data);
        }).catch(err =>{
            console.log(err);
        });
    }

    const searchList = (e) => {
        axios.post(config.DEV_URL + "/getDevicesSearch", {
            Company:sessionStorage.getItem("getCompany"),
            Filt: e.target.value
        }).then(res =>{
            setData(res.data);
        }).catch(err =>{
            console.log(err);
        });
    }

    const updateDevice = (e) => {
        let msg = ""
        if(e.target.value === " "){
            msg = window.alert("There are no records to update");
        }
        else{
                window.location = `/UpdateDevice/${e.target.value}`;
        }
     }

     const regradeDevice = (e) => {
        let msg = ""
        if(e.target.value === " "){
            msg = window.alert("There are no records to update");
        }
        else{
                window.location = `/RegradeDevice/${e.target.value}`;
        }
     }


     const deviceHistory = (e) => {
        let msg = ""
        if(e.target.value === " "){
            msg = window.alert("There are no records to update");
        }
        else{
            axios.post(config.DEV_URL + "/GetAssetHistory", {
                comp: sessionStorage.getItem("getCompany"),
                SerialNum: e.target.value
            }).then(res=>{
                console.log(res);
                if(res.data == "No Records Found"){
                    axios.post(config.DEV_URL + "/GetOldAssetHistory", {
                        comp: sessionStorage.getItem("getCompany"),
                        SerialNum: e.target.value
                    }).then(res=>{
                        console.log(res);
                        if(res.data == "No Records Found"){
                            
                            msg = window.alert("There is no history of device usage");
                        }
                        else{
                                window.location = `/historyDevice/${e.target.value}`;
                        }
                    }).catch(err => {
                        console.log(err);
                    })
                }
                else{
                        window.location = `/historyDevice/${e.target.value}`;
                }
            }).catch(err => {
                console.log(err);
            })
        }
     }

     const deviceAssignment = (e) => {
        let msg = ""
        if(e.target.value === " "){
            msg = window.alert("Device is not valid");
        }
        else{
                window.location = `/AssignDevice/${e.target.value}`;
        }
     }
     const setFiltComp = (e) => {
        //var ele = document.getElementById(`filtComp_${e.target.value}`);
        var ele = document.querySelector("input[name=filtComp]:checked");
        if(ele){
            getList(e.target.value);
        }
        else{
            getList(sessionStorage.getItem("getCompany"));
        }
    }
    const uncheckandRevert = (e) => {
        var ele = document.querySelector("input[name=filtComp]:checked");
        ele.checked = false;
        if(e){
            setFiltComp(e);
        }
    }
     const getImageVal = (e) => {
        window.open(e.target.currentSrc);
     }
 
    return(
    <div>
        <h2 className="pageTitle">Devices</h2>
        <div className="buttontoAdd">
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <button onClick={() => addDevice()}>Add Devices</button> : null}
        <span> 🔍 </span>
        <input type="search" id="searchVal" onChange={e => getSearchVal(e)}>
        </input>
        </div>

        <select className="filterList" onClick={e => filterData(e)}>
            <option value={"ASC"}>Sort By Oldest</option>
            <option value={"DESC"} selected>Sort By Newest</option>
            <option value={"Recycled"}>Sort By Recycled</option>
            <option value={"Manufacturer"}>Sort by Manufacturer</option>
            <option value={"Renewed"}>Sort By Renewed</option>
            <option value={"New"}>Sort By New</option>
            <option value={"Pool"}>Sort By Pool</option>
            <option value={"Disposable"}>Sort By Disposed</option>
        </select>

        {isAdmin == "Axia" ? 
        <div className="checkboxFilter">                
        <h6>Filter By Company</h6>
        <input name="filtComp" type="radio" value={false} onChange={(e) => uncheckandRevert(e)}></input>
        <span>Default</span>
            {finalDataSet.map(i => 
                <>
                <input name="filtComp" type="radio" value={i} onChange={e => setFiltComp(e)}></input>
                <span>{i}</span>
                </>
                )}
        </div> : <></>}

        <div>
            <table className="ContentList">
        <tr>
        <th>Ticket#</th>
        <th>Device Unique ID</th>
        <th>Manufacturer</th>
        <th>Device</th>
        <th>Serial Number</th>
        {sessionStorage.getItem("getCompany") == "Axia" ? <th>Company</th> : null}
        <th>Condition</th>
        <th>Status Of Device</th>
        <th>Wiped?</th>
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Added By</th> : null} 
        <th>Image Serial Number</th>
        <th>Image Condition</th>
        <th>Image Working</th>
        <th>Video</th>
        <th>Notes</th>
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Last Updated</th> : null}
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Updated By</th> : null}  
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Delete</th> : null}
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Update</th> : null}
        {sessionStorage.getItem("getFlag") <= 2 ? <th>History</th> : null}
        {sessionStorage.getItem("getFlag") <= 1 ? <th>Assign</th> : null}
        {sessionStorage.getItem("getFlag") == 0 ? <th>Regrade</th> : null}
        <th>Generate Barcode</th>
        <th>Generate QR Code</th>
        </tr>

    {data ? data.map(list => (
        
        <tr key={list.DeviceID ? list.DeviceID : 1}>
            <td>{list.ticketNumber ? list.ticketNumber : "-"}</td>

            <td>{list.DeviceUniqueID ? list.DeviceUniqueID : "-"}</td>
            
            <td>{list.Manufacturer ? <a href={"DeviceDetails/" + list.DeviceID}>{list.Manufacturer}</a> : null }</td>
            
            <td>{list.DeviceName ? <a href={"DeviceDetails/" + list.DeviceID}>{list.DeviceName}</a> : null }</td>
            
            <td>{list.SerialNumber ? <a href={"DeviceDetails/" + list.DeviceID}>{list.SerialNumber} </a> : null}</td>

            {sessionStorage.getItem("getCompany") == "Axia" ? <td>{list.CompanyDevice}</td> : null} 
            
            <td>{list.ConditionOfDevice == "Grade A" ? <span style={{color:'#12e354'}}>Grade A</span>
             : list.ConditionOfDevice == "Grade B" ? <span style={{color:'#7f8f5b'}}> Grade B</span>
             : list.ConditionOfDevice == "Grade C" ? <span style={{color:'#ff8c00'}}> Grade C</span>
             : list.ConditionOfDevice == "Grade D" ? <span style={{color:'Red'}}> Grade D</span>
             : null}</td>
            
            <td>{list.StatusOfDevice ? list.StatusOfDevice : null}</td>
            <td>{list.isWiped == 1 ? "✔️" : list.isWiped == 0 ? "❌" : "✔️"}</td>
            {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td>{list.AddedBy}</td> : null}
            <td className="imagePad">
            {list.SerialNumber != null && list.ImageFileSerialNum != null
            ? <a href="#" onClick={e => getImageVal(e)}><img src={require(`../../DeviceImages/${list.CompanyDevice}/${list.SerialNumber}/${list.ImageFileSerialNum}`)}/></a>
            : <img src={require(`../../Images/placeholder.webp`)}/>}</td>
            <td className="imagePad">
            {list.SerialNumber != null && list.ImageFileCondition !=null
            ? <a href="#" onClick={e => getImageVal(e)}><img src={require(`../../DeviceImages/${list.CompanyDevice}/${list.SerialNumber}/${list.ImageFileCondition}`)}/></a>
            : <img src={require(`../../Images/placeholder.webp`)}/>}</td>
            <td className="imagePad">
            {list.SerialNumber != null && list.ImageFileInUse != null
            ? <a href="#" onClick={e => getImageVal(e)}><img src={require(`../../DeviceImages/${list.CompanyDevice}/${list.SerialNumber}/${list.ImageFileInUse}`)}/></a> 
            : <img src={require(`../../Images/placeholder.webp`)}/>}</td>
            <td className="imagePad">
            {list.videoOfDevice != null
            ? <a href="#" onClick={e => getImageVal(e)}><video poster={playIcon} src={require(`../../DeviceImages/${list.CompanyDevice}/${list.SerialNumber}/${list.videoOfDevice}`)}></video></a> 
            : <img src={require(`../../Images/placeholder.webp`)}/>}</td>
            <td>{list.AdditionalNotes ? <a href={"DeviceDetails/" + list.DeviceID}>Notes</a> : null}</td>
            {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ?<td>{list.lastUpdated}</td> : null}
            {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td>{list.UpdatedBy}</td> : null}
            
            {sessionStorage.getItem("getFlag") == 1 && list.isAssigned == 0 || sessionStorage.getItem("getFlag") == 0 && list.isAssigned == 0 ? 
            <td><button className="tableButton" value={list.DeviceID ? list.DeviceID : " "} onClick={e => handleDelete(e)} title="Delete Device">
                <img src={deleteIcon}></img></button></td> 
                : sessionStorage.getItem("getFlag") == 1 && list.isAssigned == 1 || sessionStorage.getItem("getFlag") == 0 && list.isAssigned == 1 ? <td>✔</td>: null}
            
            {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td><button className="tableButton" value={list.DeviceID ? list.DeviceID : " "} onClick={e => updateDevice(e)} title="Update Device"><img src={updateIcon}></img></button></td> : null}
            {sessionStorage.getItem("getFlag") <= 2 ? <td><button className="tableButton" value={list.SerialNumber ? list.SerialNumber : " "} onClick={e => deviceHistory(e)} title="Device History"><img src={historyIcon}></img></button></td> : null}
            {sessionStorage.getItem("getFlag") <= 1 && list.isAssigned == 0 ? <td><button className="tableButton" value={list.DeviceID} onClick={e => deviceAssignment(e)} title="Assign Device"><img src={assignIcon}></img></button></td>: sessionStorage.getItem("getFlag") <= 1 && list.isAssigned == 1 ? <td>✔</td> : null}
            {sessionStorage.getItem("getFlag") == 0 ? <td><button className="tableButton" value={list.DeviceID} onClick={e => regradeDevice(e)}>Regrade</button></td>:null}
            <td><button className="tableButton" onClick={() => window.open(`/barcode/${list.DeviceUniqueID}/barcode`, "_blank", "width=800,height=800, titlebar=0, menubar=0, top=100, left=100")}><img src={barcodeIcon}></img></button></td>
            <td><button className="tableButton" onClick={() => window.open(`/barcode/${list.DeviceUniqueID}/qr`, "_blank", "width=800,height=800, titlebar=0, menubar=0, top=100, left=100")}><img src={qrIcon}></img></button></td>
        </tr>
    )) : null}
    {/*
    data.map(list => (<tr><button value={list.DeviceID} onClick={e => handleDelete(e)}>Delete</button></tr>))}
    </td>*/}
    </table>
    </div>
    </div>
    );
}

export default GetDevices;