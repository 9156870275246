import { useState, useEffect } from "react"
import axios from "axios";
import config from "../Settings.json";
import { useParams } from "react-router";
import { publicIpv4 } from "public-ip";
let userCheck = sessionStorage.getItem("getFlag");
function showPassword(){
   let pass = document.getElementById("showPass");

   if(pass.type === "password"){
      pass.type = "text";
   }
   else{
      pass.type = "password";
   }
}

const UpdateUser = () => {
    const id = useParams();
    
    const[password ,setPassword] = useState("");
    const[userStatus ,setStatus] = useState("");
    const[data, setData] = useState([]);
    const[userEmail, setUemail] = useState("");
    const[ipv4Addy, setIP] = useState("");
    const[beforeData] = useState({});

    const getIP = async () => {
        await publicIpv4().then(res => {
            setIP(res);
        })
    }

    useEffect(()=>{
      getIP();
        axios.post(config.DEV_URL + "/getUserByID", {
            userID: id.uID
        }).then(res => {
         if(res != null){
            setData(res.data);
            setStatus(res.data.map(item=>item.UserFlag));
            setUemail(res.data.map(item=>item.Email));
         }
        }).catch(err => {
         console.log(err);
        })
    }, [])
     const getPass = (e) => {
        setPassword(e.target.value);
        beforeData["Password"] = "Password Changed";
        e.preventDefault(); 
     }

     const getEmail = (e) => {
        setUemail(e.target.value);
        beforeData["Prev Email"] = data.map(i => i.Email);
        beforeData["New Email"] = e.target.value;
        e.preventDefault(); 
     }

     const getStatus = (e) => {
        setStatus(e.target.value);
        beforeData["Prev Status"] = data.map(i => i.UserFlag == 0 ? "Axia Manager" : 
        i.UserFlag == 1 ? "Admin" : 
        i.UserFlag == 2 ? "Staff" : 
        i.UserFlag == 3 ? "Offline" : i.UserFlag);
        beforeData["New Status"] = (e.target.value == 0 ? "Axia Manager" : 
        e.target.value == 1 ? "Admin" : 
        e.target.value == 2 ? "Staff" : 
        e.target.value == 3 ? "Offline" : e.target.value);
        e.preventDefault(); 
     }

     const handleSubmission = (e) => {
        axios.put(config.DEV_URL +"/UpdateUsers", {
            uID: id.uID,
            Pass: password,
            flag: userStatus,
            Email: userEmail,
            updatedBy: sessionStorage.getItem("user?name")
        }).then(res => {
         if(res != null){
            axios.put(config.DEV_URL + "/trailingChanges", {
               actionTaken: `Changed User Details: ${data.map(i => i.Username)}`,
               actionLoc: "Users Page",
               changeMade: JSON.stringify(beforeData),
               IPv4Addy: ipv4Addy,
               userName: sessionStorage.getItem("user?name")
           }).catch(err => {
               console.log(err);
           })
            window.location = "/getUsers";
         }
         else{
            window.alert("Entry Was not added, Re enter fields and try again");
         }
        }).catch(err => {
         console.log(err);
        })
        e.preventDefault();
     }


    return(
        <div>
         <div className="MainForms">
         <label className="formTitles">Update User</label>
         <form onSubmit={e => handleSubmission(e)}>

            <br></br>
            <label>Username</label>
            <br></br>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(item => item.Username)}></input>
            </div>

            <br></br>
            <label>Firstname</label>
            <br></br>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(item => item.Firstname)}></input>
            </div>
            <br></br>

            <label>Lastname</label>
            <br></br>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(item => item.Lastname)}></input>
            </div>
            <br></br>

            <label>Update Password</label>
            <br></br>
            <div className="formEffects">
            <input type="password" id="showPass" onChange={e=>getPass(e)}></input>
            <span>Show Password<input type="checkbox" onClick={showPassword}></input></span>
            </div>
            <br></br>

            <br></br>
            <label>Enter Email</label>
            <br></br>
            <div className="formEffects">
            <input type="email" onChange={e=>getEmail(e)} 
            placeholder={data.map(item => item.Email)}></input>
            </div>

            <br></br>
            <label>Choose Status</label>
            <br></br>
            <div className="formEffects">
            <select onChange={e => getStatus(e)}>
                {data.map(item => (<option value={item.UserFlag} selected>
                {item.UserFlag == 0 ? "Axia Manager" : 
                item.UserFlag == 1 ? "Administrator" :
                item.UserFlag == 2 ? "Staff" : "Offline"}
                </option>))}
                {userCheck == 0 ? <option value={0}>Axia Manager</option>:null}
                <option value={1}>Administrator</option>
                <option value={2}>Staff</option>
                <option value={3}>Offline</option>
            </select>
            </div>
            <button type="submit">
               Submit
            </button>
         </form>
         </div>
        </div>
    )
}

export default UpdateUser;