import { Link, Outlet } from "react-router-dom";
import * as Excel from "exceljs";
import axios from "axios";
import config from "./Settings.json" ;
import FileSaver from "file-saver";
const ReportMenu = () => {

    function genExcel(e){
            axios.get(config.DEV_URL + `/downloadExcel/${sessionStorage.getItem("getCompany")}/${e.target.value}`).then(res => {
                console.log(res);
                    const ele = document.createElement('a');
                    ele.href = config.DEV_URL + `/downloadExcel/${sessionStorage.getItem("getCompany")}/${e.target.value}`;
                    ele.download = 'filename.fileextension';
                    ele.click();
            }).catch(err => {
                console.log(err);
            })
    }

    return(
        <div>
            <div className="reportHeader"><h1>Links to Report Pages</h1></div>
            <div className="reportLinkContainer">
                <div className="reportLinks">
                {sessionStorage.getItem("getFlag") <= 2 ? 
                <>
                <Link to="/employeesReport"><button>Employee Reports</button></Link>
                <Link to="/assetReport"><button>Asset Devices Reports</button></Link>
                <Link to="/fieldReport"><button>Field Asset Reports</button></Link>
                <button style={{width: "fit-content"}} value="All" onClick={(e) => genExcel(e)}>Excel Report All Devices</button>
                <button style={{width: "fit-content"}} value="Pool" onClick={(e) => genExcel(e)}>Excel Report Devices In Pool</button>
                <button style={{width: "fit-content"}} value="Disposable" onClick={(e) => genExcel(e)}>Excel Report Disposed Devices</button>
                <button style={{width: "fit-content"}} value="1" onClick={(e) => genExcel(e)}>Excel Report Devices On Field</button>
                <button style={{width: "fit-content"}} value="0" onClick={(e) => genExcel(e)}>Excel Report Devices Not Assigned</button> 
                </> 
                : null}
                </div>
            </div>
            <Outlet/>
        </div>
    )
}

export default ReportMenu;