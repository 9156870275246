import axios from "axios";
import { useEffect, useState } from "react";
import config from "../Settings.json";
import playIcon from "../buttonIcons/play-round-icon.png";
import historyIcon from "../buttonIcons/history-line-icon.png";

const DisposableDev = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        axios.post(config.DEV_URL + "/GetDisposedDevices", {
            comp: sessionStorage.getItem("getCompany"),
        }).then(res => {
            if(res.data != null){
                setData(res.data);
            }
        }).catch(err => {
            console.log(err);
        })
    }, [])

    const filterData = (e) => {
        axios.post(config.DEV_URL + "/getDeviceFilterDisposable", {
            Company: sessionStorage.getItem("getCompany"),
            Filter: e.target.value
        }).then(res => {
            if(res.data != null){
                setData(res.data);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    const searchData = (e) => {
        axios.post(config.DEV_URL + "/getDevicesSearchDisposable", {
            Company: sessionStorage.getItem("getCompany"),
            Filt: e.target.value
        }).then(res => {
            setData(res.data);
        }).catch(err => {
            console.log(err);
        })
    }

    const getImageVal = (e) => {
        window.open = e.target.currentSrc;
    }
    const deviceHistory = (e) => {
        let msg = ""
        if(e.target.value === " "){
            msg = window.alert("There are no records to update");
        }
        else{
            axios.post(config.DEV_URL + "/GetAssetHistory", {
                comp: sessionStorage.getItem("getCompany"),
                SerialNum: e.target.value
            }).then(res=>{
                console.log(res);
                if(res.data == "No Records Found"){
                    axios.post(config.DEV_URL + "/GetOldAssetHistory", {
                        comp: sessionStorage.getItem("getCompany"),
                        SerialNum: e.target.value
                    }).then(res=>{
                        console.log(res);
                        if(res.data == "No Records Found"){
                            
                            msg = window.alert("There is no history of device usage");
                        }
                        else{
                                window.location = `/historyDevice/${e.target.value}`;
                        }
                    }).catch(err => {
                        console.log(err);
                    })
                }
                else{
                        window.location = `/historyDevice/${e.target.value}`;
                }
            }).catch(err => {
                console.log(err);
            })
        }
     }

    return (
        <>
    <div>
        <h2 className="pageTitle">Disposable Devices</h2>
        <div className="buttontoAdd">
        <span> 🔍 </span>
        <input type="search" id="searchVal" onChange={e => searchData(e)}>
        </input>
        </div>

        <select className="filterList" onClick={e => filterData(e)}>
            <option value={"ASC"}>Sort By Oldest</option>
            <option value={"DESC"} selected>Sort By Newest</option>
        </select>

        <div>
            <table className="ContentList">
        
        <tr>
        <th>Ticket Number</th>
        <th>Device Unique ID</th>
        <th>Manufacturer</th>
        <th>Device</th>
        <th>Serial Number</th>
        {sessionStorage.getItem("getCompany") == "Axia" ? <th>Company</th> : null}
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Added By</th> : null} 
        <th>Image Serial Number</th>
        <th>Image Condition</th>
        <th>Image Working</th>
        <th>Video</th>
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Last Updated</th> : null}
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Updated By</th> : null}  
        {sessionStorage.getItem("getFlag") <= 2 ? <th>History</th> : null}
        </tr>

    {data != null ? data.map(list => (
        
        <tr key={list.DeviceID ? list.DeviceID : 1}>
            <td>{list.ticketNumber ? list.ticketNumber : "-"}</td>
            <td>{list.DeviceUniqueID ? list.DeviceUniqueID : "-"}</td>

            <td>{list.Manufacturer ? <a href={"DeviceDetails/" + list.DeviceID}>{list.Manufacturer}</a> : null }</td>
            
            <td>{list.DeviceName ? <a href={"DeviceDetails/" + list.DeviceID}>{list.DeviceName}</a> : null }</td>
            
            <td>{list.SerialNumber ? <a href={"DeviceDetails/" + list.DeviceID}>{list.SerialNumber} </a> : null}</td>

            {sessionStorage.getItem("getCompany") == "Axia" ? <td>{list.CompanyDevice}</td> : null}
            {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td>{list.AddedBy}</td> : null}
            <td className="imagePad">
            {list.SerialNumber != null && list.ImageFileSerialNum != null
            ? <a href="#" onClick={e => getImageVal(e)}><img src={require(`../../DeviceImages/${list.CompanyDevice}/${list.SerialNumber}/${list.ImageFileSerialNum}`)}/></a>
            : <img src={require(`../../Images/placeholder.webp`)}/>}</td>
            <td className="imagePad">
            {list.SerialNumber != null && list.ImageFileCondition !=null
            ? <a href="#" onClick={e => getImageVal(e)}><img src={require(`../../DeviceImages/${list.CompanyDevice}/${list.SerialNumber}/${list.ImageFileCondition}`)}/></a>
            : <img src={require(`../../Images/placeholder.webp`)}/>}</td>
            <td className="imagePad">
            {list.SerialNumber != null && list.ImageFileInUse != null
            ? <a href="#" onClick={e => getImageVal(e)}><img src={require(`../../DeviceImages/${list.CompanyDevice}/${list.SerialNumber}/${list.ImageFileInUse}`)}/></a> 
            : <img src={require(`../../Images/placeholder.webp`)}/>}</td>
            <td className="imagePad">
            {list.videoOfDevice != null
            ? <a href="#" onClick={e => getImageVal(e)}><video poster={playIcon} src={require(`../../DeviceImages/${list.CompanyDevice}/${list.SerialNumber}/${list.videoOfDevice}`)}></video></a> 
            : <img src={require(`../../Images/placeholder.webp`)}/>}</td>
            {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ?<td>{list.lastUpdated}</td> : null}
            {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td>{list.UpdatedBy}</td> : null}

            {sessionStorage.getItem("getFlag") <= 2 ? <td><button className="tableButton" value={list.SerialNumber ? list.SerialNumber : " "} onClick={e => deviceHistory(e)} title="Device History"><img src={historyIcon}></img></button></td> : null}
        </tr>
    )) : <h1>No Devices to list</h1>}
    {/*
    data.map(list => (<tr><button value={list.DeviceID} onClick={e => handleDelete(e)}>Delete</button></tr>))}
    </td>*/}
    </table>
    </div>
    </div>
    </>
    )
}

export default DisposableDev;