import { useEffect, useState } from "react";
import axios from "axios";
import AddDevice from "./addDevices";
const config = require("../Settings.json");

const GetDevicesReport = () => {

    const [data, setData] = useState([]);
    const [latestTime, setTime] = useState("");
    useEffect(()=>{
        axios.post(config.DEV_URL + "/GetDevicesLimit", {
            comp: sessionStorage.getItem("getCompany")
        }).then(res=>{
            if(res.data == "No Records Found"){
                setData(["null"]);
            }
            else{
            setData(res.data);
            }
        }).catch(err => {
            console.log(err);
        })
        getTime();
    }, [])

    function getTime(){
        setTime(new Date());
    }

    setTimeout(getTime, 1000);

    const getSearchVal = (e) => {
        searchList(e);
        /*let onClickFunc = document.getElementById("searchVal");
        if(onClickFunc.addEventListener("keypress", function(c){
            if(c.key === "Enter"){
                searchList(e);
                c.stopImmediatePropagation();
            }
        }))*/
        e.preventDefault();
    }


    const searchList = (e) => {
        axios.post(config.DEV_URL + "/getDevicesSearchLimit", {
            Company:sessionStorage.getItem("getCompany"),
            Filt: e.target.value
        }).then(res =>{
            setData(res.data);
            console.log(res.data);
        }).catch(err =>{
            console.log(err);
        });
    }
 
    return(
    <div>
        <h2 className="pageTitle">Devices</h2>
        <div className="buttontoAdd"><button onClick={window.print}>Print</button>
        <span> 🔍 </span>
        <input type="search" id="searchVal" onChange={e => getSearchVal(e)}>
        </input></div>
        <div className="reportTime">
        <h3>Printed: {latestTime.toString().split("GMT")[0]}</h3>
        </div>

        <div>
            <table className="ContentList">
        
        <tr>
        <th>Manufacturer</th>
        <th>Device</th>
        <th>Serial Number</th>
        {sessionStorage.getItem("getCompany") == "Axia" ? <th>Company</th> : null}
        <th>Condition</th>
        <th>Status Of Device</th>
        <th>Device Added</th>
        <th>Added By</th>
        <th>Last Updated</th>
        <th>Updated By</th>
        </tr>

    {data ? data.map(list => (
        
        <tr key={list.DeviceID ? list.DeviceID : 1}>
            <td>{list.Manufacturer ? list.Manufacturer : null }</td>
            <td>{list.DeviceName ? list.DeviceName : null }</td>
            <td>{list.SerialNumber ? list.SerialNumber : null}</td>
            {sessionStorage.getItem("getCompany") == "Axia" ? <td>{list.CompanyDevice}</td> : null}
            <td>{list.ConditionOfDevice ? list.ConditionOfDevice : null}</td>
            <td>{list.StatusOfDevice ? list.StatusOfDevice : null}</td>
            <td>{list.TimeAdded}</td>
            <td>{list.AddedBy}</td>
            <td>{list.lastUpdated}</td>
            <td>{list.UpdatedBy}</td>  
            </tr>
    )) : null}
    </table>
    </div>
    </div>
    );
}

export default GetDevicesReport;