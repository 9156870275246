import axios from "axios";
import config from "../Settings.json";
import { publicIpv4 } from "public-ip";
import { useParams } from "react-router";
const image = require("../../Images/CompanyLogos/Axia.png");
const { useState, useEffect } = require("react");

const FactorVerify =  () => {
    const [error, setError] = useState("Please Enter Credientials");
    const [ipv4Res, setIPRes] = useState("");
    const [sKey, setKey] = useState("");
    const [data, setData] = useState([]);
    const [username, setUsername] = useState("");
    const [inputKey, setInputKey] = useState("1");
    const webToken = useParams();

    useEffect(() => {
        getIp();
        axios.put(config.DEV_URL + "/validateAuthToken", {
            token: webToken.tokenAuth
        }).then(res => {
            setKey(res.data.secretPass[0]);
            setUsername(res.data.userName[0]);
            
            setData(res.data.rawData);
        }).catch(err => {
            console.log(err);
        })
    }, [])

    const getIp = async() => {
        await publicIpv4().then(res => {
            setIPRes(res);
        })
    }

    const getInputKey = (e) => {
        setInputKey(e.target.value);
    }
    const submission = (e) => {   
        axios.post(config.DEV_URL + "/otpVerifyOnLogin", {
            userName: username,
            secretPass: sKey,
            userInput: inputKey,
            IPv4Address: ipv4Res
        }).then(res => {
            console.log(res);
            if(res.data == true){
                let isLogged = true;
                let comp = data.map(i => i.Company);
                let userFlag = data.map(i => i.UserFlag);
                let userID = data.map(i => i.UserID);
                let username = data.map(i => i.Username);
                sessionStorage.setItem("getFlag", userFlag);
                sessionStorage.setItem("getCompany", comp);
                sessionStorage.setItem("isLogged", isLogged);
                sessionStorage.setItem("user?name", username);
                sessionStorage.setItem("userIdentity?id", userID);
                window.location = "/";
            }
            else{
                setError("Wrong Credientials");
            }
        }).catch(err => {
            console.log(err);
        })
        e.preventDefault();
    }


    let backImage = null;
    try{
    backImage = require("../../Images/backgroundImage.jpg");
    }
    catch{
        backImage = null;
    }

    return(
        
        <div>
             {/*backImage != null ? <img className="backgroundImage" src={backImage}></img>:null*/}
            {<div className="loginBackground"></div>}
            {<div className="loginBackground2"></div>}
            <div className="loginTitle"><h1>Axia LMS</h1></div>
            <div className="MainLogo"><img src={image}></img></div>
           
            <div className="loginSection">
            <form>
                <label value="Username">Code</label> <br></br>
                <input type="text" onChange={e => getInputKey(e)}></input><br></br>
                <p>{error}</p>
                <button onClick={e => submission(e)}>Submit</button>
            </form>
            </div>
            <div className="loginFooter">
            <footer>
                Designed by Axia Design (An Axia Computer Systems Subsidiary)
            </footer>
            </div>
        </div>
    );
}

export default FactorVerify;