import axios from "axios";
import { useEffect, useState } from "react";
import config from "../Settings.json";
import deleteIcon from "../buttonIcons/folder-delete-icon.png";
import updateIcon from "../buttonIcons/folder-refresh-icon.png";
import { publicIpv4 } from "public-ip";


const GetAssets = () => {
const[data, setData] = useState([]);
const[ipv4Add, setIP] = useState("");
async function getIP(){
    await publicIpv4().then(res => {
        setIP(res);
    })
}
useEffect(() =>{
    getIP();
    axios.post(config.DEV_URL + "/getAllMainDetails", {
        comp :sessionStorage.getItem("getCompany")
    }).then(res =>{
        setData(res.data);
    }).catch(err =>{
        console.log(err);
    });

}, []);


const handleDelete = async (e) =>{
    
    if(window.confirm("Do you want to delete this field asset?") === true){
        await axios.post(config.DEV_URL + "/getAssetsByID", {
            Company: sessionStorage.getItem("getCompany"),
            assetID: e.target.value
        }).then(dataResult => {

            if(dataResult.data.map(list => list.AssetInUse) == "true"){
                window.alert("Cannot be deleted: Asset still in Use");
            }
            else{
                if(dataResult){
                 
                axios.put(config.DEV_URL + "/trailingChanges", {
                    userName: sessionStorage.getItem("user?name"),
                    actionTaken: "Deleting Field Listing",
                    actionLoc: "Field Page",
                    changeMade: JSON.stringify(dataResult.data),
                    IPv4Addy: ipv4Add
                }).catch(err => {
                    console.log(err);
                })


                axios.post(config.DEV_URL + "/assetTrail", {
                    Date: dataResult.data.map(list => list.AssetGivenTime),
                    ownerAsset: dataResult.data.map(list => list.OwnerOfAsset),
                    nameAsset: dataResult.data.map(list => list.AssetName),
                    typeAsset: dataResult.data.map(list => list.AssetType),
                    inUseAsset: dataResult.data.map(list => list.AssetInUse),
                    assetComp: dataResult.data.map(list => list.AssetComp),
                    expAsset: dataResult.data.map(list => list.AssetExpiry),
                    serialNum: dataResult.data.map(list => list.SerialNumber),
                    assetNotes: dataResult.data.map(list => list.AssetNotes),
                    assetDepartment: dataResult.data.map(list => list.OwnerDepartment),
                    assetDivision: dataResult.data.map(list => list.OwnerDivision),
                    assetLocation: dataResult.data.map(list => list.OwnerLocation),
                    assetDelBy: sessionStorage.getItem("user?name"),
                    assTicket: dataResult.data.map(list => list.assetTicket),
                    devTicket: dataResult.data.map(list => list.deviceTicket),
                    assetStatus: dataResult.data.map(list => list.DeviceStatus),
                }).then(res => {
                    console.log(res.data);
                    if(res == null){
                        console.log(res);
                    }
                    else{ 
                        axios.post(config.DEV_URL +"/DeleteAssetAndReplace", {
                            id: e.target.value
                        }).then(res => {
                            if(res == null){
                                console.log("Invalid ID");
                            }
                            else{
                                window.location.reload(); 
                                //console.log(res);
                            }
                        }).catch(err => {
                            console.log(err);
                        })
                    }
                }).catch(err => {
                    console.log(err);
                })
            }
          }
        })
    }
  else{
    console.log("aborted");
  }
}

const addAsset = () => {
    window.location = "/add-assets";
}


 const filterData = (e) =>{
    axios.post(config.DEV_URL + "/getAssetsFilter", {
        Company:sessionStorage.getItem("getCompany"),
        Filter: e.target.value
    }).then(res =>{
        setData(res.data);
        //console.log(res.data);
    }).catch(err =>{
        console.log(err);
    });
}

const getSearchVal = (e) => {
    searchList(e);
    e.preventDefault();
}

const updateAsset = (e) => {
    if(e.target.value === " "){
        window.alert("There are no records to update");
    }
    else{
        window.location = `/UpdatingAsset/${e.target.value}`;
    }
 }

 const searchList = (e) => {
    axios.post(config.DEV_URL + "/getAssetSearch", {
        assetComp: sessionStorage.getItem("getCompany"),
        Filt: e.target.value
    }).then(res =>{
        setData(res.data);
        //console.log(res.data);
    }).catch(err =>{
        console.log(err);
    });
}

return(
    <div>
        <h2 className="pageTitle">Field Assets</h2>
        <div className="buttontoAdd">
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <button onClick={() => addAsset()}>Add An Asset</button> : null }
        <span> 🔍 </span>
        <input type="search" id="searchVal" onChange={e => getSearchVal(e)}>
        </input>
        </div>

        <select className="filterList" onClick={e => filterData(e)}>
            <option value={"ASC"}>Sort By Oldest</option>
            <option value={"DESC"} selected>Sort By Newest</option>
            <option value={"InUse"}>Show Assets In Use</option>
            <option value={"NoUse"}>Sort Assets Not In Use</option>
        </select>

    <table className="ContentList" id="limitList">
        
    <thead>
        <tr>
        {sessionStorage.getItem("getCompany") == "Axia" ?<th>Ticket#</th> :null}
        <th>Owner Name</th>
        <th>Division</th>
        <th>Department</th>
        <th>Location</th>
        <th>Device</th>
        <th>Serial Number</th>
        {sessionStorage.getItem("getCompany") == "Axia" ? <th>Company</th> : null}
        <th>Type Of Asset</th>
        <th>Is Asset In Use</th>
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Added By</th> : null} 
        <th>Date Asset Given</th>
        <th>Asset Expiry Date</th>
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Last Updated</th> : null} 
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Updated By</th> : null} 
        <th>Notes</th>
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ?<th>Delete</th>:null}
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ?<th>Update</th>:null}
        {/*<th>Print or Save PDF</th>*/}
        </tr>
        </thead>
    <tbody>

    {data ? data.map(list => (
    <tr key={list.AssetID ? list.AssetID : 1}>
    {sessionStorage.getItem("getCompany") == "Axia" ? <td>{list.assetTicket}</td> : null}
    <td>{list.OwnerOfAsset ? <a href={"AssetsContent/" + list.AssetID}>{list.OwnerOfAsset}</a> : null}</td>
    <td>{list.OwnerDivision ? <a href={"AssetsContent/" + list.AssetID}>{list.OwnerDivision}</a> : null}</td>
    <td>{list.OwnerDepartment ? <a href={"AssetsContent/" + list.AssetID}>{list.OwnerDepartment}</a> : null}</td>
    <td>{list.OwnerLocation ? <a href={"AssetsContent/" + list.AssetID}>{list.OwnerLocation}</a>: null}</td>
    <td>{list.AssetName ? <a href={"AssetsContent/" + list.AssetID}>{list.AssetName}</a> : null}</td>
    <td>{list.SerialNumber ? <a href={"AssetsContent/" + list.AssetID}>{list.SerialNumber}</a> : null}</td>
    {sessionStorage.getItem("getCompany") == "Axia" && list.AssetComp != null ? <td>{list.AssetComp}</td> : null}
    <td>{list.AssetType ? <a href={"AssetsContent/" + list.AssetID}>{list.AssetType}</a> : null}</td>
    <td>{list.AssetInUse == "true" ? "Yes" : list.AssetInUse == "false" ? "No" : null}</td>
    {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td>{list.AddedBy}</td>  : null}
    <td>{list.AssetTime ? (list.AssetTime.replace("T", " ").split(".0")[0]) : null}</td>
    <td>{list.AssetExpiry ? (list.AssetExpiry.split(" ")[0]) : "No Expiry Date Given"}</td>
    {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td>{list.AssetLastUpdate}</td> : null}
    {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td>{list.UpdatedBy}</td> : null}
    <td className="assetNotes">{list.AssetNotes ? <a href={"AssetsContent/" + list.AssetID}>Notes</a> : "No Notes"}</td>
    {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td><button value={list.AssetID} className="tableButton" 
    onClick={e => handleDelete(e)} title="Delete Field"><img src={deleteIcon}></img></button></td>: null}
    {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td><button value={list.AssetID} className="tableButton" 
    onClick={e => updateAsset(e)} title="Update Field"><img src={updateIcon}></img></button></td>: null}
    {/*<td><button value={list.AssetID} onClick={e => printAsset(e)} className="tableButton">Print</button></td>*/}
    </tr>
    )) : null}
    </tbody>
    </table>
    </div>
    )
}

export default GetAssets;