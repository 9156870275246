import config from "../Settings.json";
import axios from "axios";
import { useState, useEffect } from "react";
import { publicIpv4 } from "public-ip";

const AddAssets = () => {
    const [dev, getDevice] = useState([]);
    const [owners, getOwners] = useState([]);
    const [notes, getNote] = useState("No Notes");
    const [devStatus, setDevStatus] = useState("");
    const [assetOwner, getAssetOwner] = useState(null);
    const [empDiv, setEmpDiv] = useState([]);
    const [empLoc, setEmpLoc] = useState([]);
    const [empDep, setEmpDep] = useState([]);
    const [setupDate, getSetup] = useState(null);
    const [expDate, getExp] = useState(null);
    const [typeOfAsset, getType] = useState(null);
    const [inUse, getInUse] = useState("true");
    const [error, setError] = useState(null);
    const [prevData, setPrev] = useState([]);
    const [devName, setDevName] = useState("");
    const [serial, setSerialNo] = useState("");
    const [compData, setComp] = useState(sessionStorage.getItem("getCompany"));
    const [cData, setCData] = useState([]);
    const [dID, setDevID] = useState("");
    const [dTick, setDevTickNum] = useState("");
    const [aTick, setAssTickNum] = useState("");
    const [ipv4Add, setIP] = useState("");

    useEffect(() => {
        axios.post(config.DEV_URL + "/GetDevicesNotAssigned", {
            comp: sessionStorage.getItem("getCompany"),
            assigned: 0,
        }).then(res =>{
            if(res){
                getDevice(res.data);
            }
            else{
                //console.log("No Devices");
                getDevice([]);
            }
        }).catch(err => {
            console.log(err);
        })
        axios.put(config.DEV_URL + "/getEmployees", {
            Company: sessionStorage.getItem("getCompany")
        }).then(res2 => {
            if(res2){
                getOwners(res2.data);
            }
            else{
                //console.log("No Employees");
                getOwners([]);
            }
        })
        getIP();
        axios.put(config.DEV_URL + "/getCompanies").then(res =>{
            setCData(res.data);
        }).catch(err =>{
            console.log(err);
        });
        
    }, [])

    let getIP = async () => {
        await publicIpv4().then(res => {
            setIP(res);
        })
    }

    const getPreviousData = (e) => {
        axios.put(config.DEV_URL + "/getPrev", {
            owner: e
        }).then(res => {
            setPrev(res.data);
            })
    }

    const setDevices = (e) => {
         axios.post(config.DEV_URL + "/GetDevicesByID", {
            comp:sessionStorage.getItem("getCompany"),
            devID: e.target.value
         }).then(res => {
            if(res){
            setDevID(e.target.value);
            setDevName(res.data.map(list => list.DeviceName));
            setSerialNo(res.data.map(list => list.SerialNumber));
            setDevStatus(res.data.map(list => list.ConditionOfDevice));
            setDevTickNum(res.data.map(list => list.ticketNumber));
            document.getElementById("serialNum").placeholder = res.data.map(list => list.SerialNumber);
            }
            else{
            setDevName(null);
            setSerialNo(null);
            }
         }).catch(err => {
            console.log(err);
         })
        e.preventDefault();
    }

    const setDate = (e) => {
        getSetup(e.target.value);
        e.preventDefault();
    }

    const getCName = (e) => {
        if(sessionStorage.getItem("getCompany") == "Axia"){
            setComp(e.target.value);  

            axios.put(config.DEV_URL + "/getEmployees", {
                Company: e.target.value
            }).then(res2 => {
                if(res2){
                    getOwners(res2.data);
                }
                else{
                    //console.log("No Employees");
                    getOwners([]);
                }
            });

            axios.post(config.DEV_URL + "/GetDevicesNotAssigned", {
             comp: e.target.value
              }).then(res =>{
              if(res){
                getDevice(res.data);
              }
              else{
                //console.log("No Devices");
                getDevice([]);
              }
            }).catch(err => {
             console.log(err);
          })
        }
        else{
            setComp(sessionStorage.getItem("getCompany"));
        }
        e.preventDefault();
    }

    const setNotes = (e) => {
        getNote(e.target.value);
        e.preventDefault();
    }

    const setExpDate = (e) => {
        getExp(e.target.value);
        e.preventDefault();
    }

    const getAssetTick = (e) => {
        setAssTickNum(e.target.value);
        e.preventDefault();
    }

    const setOwner = (e) => {
       axios.post(config.DEV_URL + "/getEmployeesByID", {
            Company : sessionStorage.getItem("getCompany"),
            uID:e.target.value
        }).then(res =>{
            if(res != null){
                setEmpDiv(res.data.map(list => list.EmpDivision));
                setEmpDep(res.data.map(list => list.EmpDepartment));
                setEmpLoc(res.data.map(list => list.EmpLocation));
                getPreviousData(res.data.map(list => list.EmpFirstName + " " + list.EmpLastName)); 
                getAssetOwner(res.data.map(list => list.EmpFirstName + " " + list.EmpLastName));   
                document.getElementById("ownerDetails1").placeholder = res.data.map(list => list.EmpLocation);
                document.getElementById("ownerDetails2").placeholder = res.data.map(list => list.EmpDepartment);
                document.getElementById("ownerDetails3").placeholder = res.data.map(list => list.EmpDivision );   
            }
                else{
                setEmpDiv(null);
                setEmpDep(null);
                setEmpLoc(null);
                setDevName(null);
                setSerialNo(null);
                }
             }).catch(err => {
                console.log(err);
             })
            e.preventDefault();
    }

    const setInUse = (e) => {
        
        getInUse(e.target.value);
        e.preventDefault();
    }
    const setType = (e) => {
        getType(e.target.value);
        e.preventDefault();
    }


    const handleSubmission = (e) => {
        let changesArr = {};
        changesArr["Device Ticket"] = dTick;
        changesArr["ownerAsset"] = assetOwner;
        changesArr["devName"] = devName;
        changesArr["Company"] = compData;
        changesArr["Department"] = empDep;
        changesArr["Division"] = empDiv;
        changesArr["Location"] = empLoc;
        changesArr["Serial Number"] = serial;
        changesArr["Status"] = devStatus;
        changesArr["Notes"] = notes;
        changesArr["typeOfAsset"] = typeOfAsset;
        changesArr["In Use"] = inUse;
        changesArr["Setup Date"] = setupDate;
        changesArr["Asset Expiry"] = expDate != "" ? expDate : "No Expiry";
        changesArr["Added By"] = sessionStorage.getItem("user?name");
        
        if(expDate == setupDate || expDate < setupDate){
            setError("Error: Expiry Date and Asset Date MUST have a 24 Hour difference in Between")
        }
        else{
        axios.post(config.DEV_URL + "/register-asset", {
            date: setupDate,
            ownerAsset: assetOwner,
            nameAsset: devName,
            typeAsset: typeOfAsset,
            inUseAsset: inUse,
            expAsset: expDate,
            assetComp: compData,
            SerialNum: serial,
            assetNotes: notes,
            assetDepartment: empDep,
            assetDivision: empDiv,
            assetLocation: empLoc,
            assetAddedBy: sessionStorage.getItem("user?name"),
            assetStatus: devStatus,
            devTicket: dTick,
            assTicket: aTick,

        }).then(async (res) => {
            if(res != null){
                if(inUse == "true"){
                    axios.post(config.DEV_URL + "/deviceAssignUpdate", {
                        devID: dID,
                        isAssigned: true
                    }).then(res => {
                        //console.log(res);
                        window.location = "/CurrentHardwarePool";
                    }).catch(err => {
                        console.log(err);
                    })
                }
                else{
                    axios.post(config.DEV_URL + "/deviceAssignUpdate", {
                        devID: dID,
                        isAssigned: false
                    }).then(res => {
                        window.location = "/AssetsList";
                    }).catch(err => {
                        console.log(err);
                    })
                }

                await axios.put(config.DEV_URL + "/trailingChanges", {
                    userName: sessionStorage.getItem("user?name"),
                    actionTaken: "Adding onto Field",
                    actionLoc: "Add Asset Page",
                    changeMade: JSON.stringify(changesArr),
                    IPv4Addy: ipv4Add
                }).then(res2 => {
                    console.log(res2);
                }).catch(err => {
                    console.log(err);
                })

            }
            else{
                setError("An Error has occurred");
            }
        }).catch(err => {
            console.log(err);
        })
    }
    e.preventDefault();
  }

    return(
        <div>
            <div className="MainForms">
            <div className="formLeft">
            <form onSubmit={e => handleSubmission(e)}>

            <label>Company</label>
            <div className="formEffects">
            {sessionStorage.getItem("getCompany") == "Axia" ? 
            <select required onChange={e => getCName(e)}>
            <option selected>{sessionStorage.getItem("getCompany")}</option>
            {cData != "" && sessionStorage.getItem("getCompany") == "Axia" ? cData.map(list => (<option value={list.CompanyName}>{list.CompanyName}</option>)) : null}
            </select> : 
            <select required disabled onChange={e => getCName(e)}>
            <option selected>{sessionStorage.getItem("getCompany")}</option>
            </select>}
            <br></br>
            </div>

            {sessionStorage.getItem("getCompany") === "Axia" ?
            <div>
            <label>Ticket Number (Optional)</label>
            <div className="formEffects">
            <input type="text" defaultValue={"#"} placeholder="Enter Ticket Number Asset relates to, keep empty if not" onChange={e => getAssetTick(e)}></input>
            <br></br>
            </div>
            </div>
            : null }
            
            <label>User of Asset</label>
            <br></br>
            <div className="formEffects">
            <select id="nameList" required onChange={e => setOwner(e)}>
                <option value={""} required selected disabled>Please Select An Employee</option>
                {owners.map((list, key) => <option key={key} value={list.EmpID}>{list.EmpFirstName + " " + list.EmpLastName}</option>)}
            </select>
            </div>
            <br></br>

            <label>User Location</label>
            <div className="formEffects">
            <input type="text" id="ownerDetails1" placeholder="" disabled></input>
            <br></br>
            </div>
       

            <label>User Department</label>
            <div className="formEffects">
            <input type="text" id="ownerDetails2" placeholder="" disabled></input>
            <br></br>
            </div>


            <label>User Division</label>
            <div className="formEffects">
            <input type="text" id="ownerDetails3" placeholder="" disabled></input>
            <br></br>
            </div>


            <br></br>
            <label>Asset Device</label>
            <div className="formEffects">
            <select required onChange={e => setDevices(e)}>
                <option value="" disabled selected>Choose a Device</option>
                {dev != "No Records Found" ? dev.map(list => (<option value={list.DeviceID}>{list.DeviceName}</option>)) : <option></option>}
            </select>
            <br></br>
            </div>


            <label>Serial Number</label>
            <div className="formEffects">
            <input type="text" id="serialNum" placeholder="xxxxx-xxxxx-xxxxx" disabled></input>
            <br></br>
            </div>


            <label>Date Asset Given</label>
            <div className="formEffects">
            <input type="date" required onChange={e => setDate(e)}></input>
            </div>
            <br></br>

            <label>Asset Expiry Date(optional)</label>
            <div className="formEffects">
            <input type="date" onChange={e => setExpDate(e)}></input>
            </div>
            <br></br>

            <label>Is Asset Currently In Use?</label>
            <div className="formEffects">
            <select id="inUse" required onChange={e => setInUse(e)}>
                <option value="#" selected disabled>Please Choose An Option</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
            </select>
            </div>
            <br></br>

            <label>Type Of Asset</label>
            <div className="formEffects">
            <select required onChange={e => setType(e)}>
                <option value="#" selected disabled>Please Choose An Option</option>
                <option value="Phone">Phone</option>
                <option value="Desktop">Desktop</option>
                <option value="Laptop">Laptop</option>
                <option value="Other">Other</option>
            </select>
            </div>
            <br></br>

            <label>Notes</label>
            <div className="formEffects">
                <textarea onChange={e => setNotes(e)} maxLength={5000}></textarea>
            </div>
            <br></br>
            
            <p>{error}</p>
            <button type="submit">Submit</button>
            </form>
            <table className="prevRecord">
                <thead>
                <tr><p>Previous Records</p></tr>
                    <tr>
                        {sessionStorage.getItem("getCompany") === "Axia" ? <th>Ticket#</th> : null}
                        <th>Employee Name</th>
                        <th>Division</th>
                        <th>Department</th>
                        <th>Location</th>
                        <th>Asset Device</th>
                        <th>Type Of Device</th>
                        <th>Is Asset In Use</th>
                        <th>Time Asset Added</th>
                        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Added By</th> : null}
                        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Last Updated</th> : null} 
                        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Updated By</th> : null} 
                        </tr>
                        </thead>
                    <tbody>
                        {prevData.map(list => (
                        <tr key={list.AssetID}>
                            {sessionStorage.getItem("getCompany") === "Axia" ? <th>{list.assetTicket}</th> : null}
                            <td>{list.OwnerOfAsset}</td>
                            <td>{list.OwnerDivision}</td>
                            <td>{list.OwnerDepartment}</td>
                            <td>{list.OwnerLocation}</td>
                            <td>{list.AssetName}</td>
                            <td>{list.AssetType}</td>
                            <td>{list.AssetInUse == "true" ? "Yes" : "No"}</td>
                            <td>{list.AssetGivenTime.split("T")[0]}</td>
                            {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td>{list.AddedBy}</td>  : null}
                            {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td>{list.AssetLastUpdate}</td> : null}
                            {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td>{list.UpdatedBy}</td> : null}
                            </tr>
                            ))}
                    </tbody>
                </table>
                
             </div>
            </div>
        </div>
    )
}

export default AddAssets;