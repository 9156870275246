import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";
const config = require( "../Settings.json");


const DeviceFromCodes = () =>{
    const ID = useParams();
    const [data, setData] = useState([]);
    const [prevData, setPrev] = useState([]);

    useEffect(()=>{
    
        axios.post(config.DEV_URL + "/GetDevicesByID", {
            devID: ID.token,
            comp:"Axia"//sessionStorage.getItem("getCompany")
        }).then(res => {
                if(res){
                    setData(res.data);
                    axios.put(config.DEV_URL + "/getPrevByDevice", {
                    serialNum: res.data.map(list => list.SerialNumber)
                    }).then(res => {
                        setPrev(res.data);
                    })
                    setPrev([]);
                }
            })
    }, [])

    const getImageVal = (e) => {
        window.open(e.target.currentSrc);
     }

    return(
        <div>

        <div className="MainForms">
        <div className="formLeft">
         <form>
            <label>Manufacturer *</label>
            <br></br>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(list => list.Manufacturer)}></input>
            </div>
            <br></br>

            {
            sessionStorage.getItem("getCompany") == "Axia" ?
            <div>
            <label>Ticket Number</label>
            <br></br>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(list => list.ticketNumber)}></input>
            </div>
            <br></br></div> : null
            }

            <label>Device Name</label>
            <br></br>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(list => list.DeviceName)}></input>
            </div>
            <br></br>

            <label>Equipment ID</label>
            <br></br>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(list => list.DeviceUniqueID)}></input>
            </div>
            <br></br>

            <div className="formEffects">
            <label>Serial Number</label>
            <br></br>
            <input type="text" disabled placeholder={data.map(list => list.SerialNumber)}></input>
            <br></br>
            </div>

            <br></br>
            <label>Condition Grade</label>
            <br></br>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(list => list.ConditionOfDevice)}></input>
            </div>

            <br></br>
            <label>Status of Device</label>
            <br></br>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(list => list.StatusOfDevice)}></input>
            </div>

            <div>
            <label>Is Wiped?</label>
            <br></br>
            <div className="formEffects">
            <input type="text" placeholder={data.map(list => list.isWiped == 1 ? "Yes" : list.isWiped == 0 ? "No" : "Yes")}></input>
            </div>
            <br></br></div>


            <label>Notes</label>
            <div className="formEffects">
                <textarea style={{width:"580px", height:"300px", resize: "vertical"}}  disabled placeholder={data.map(list => list.AdditionalNotes)}></textarea>
            </div>
            <br></br>
            </form>
         </div>
         </div>
    </div>
    );
}

export default DeviceFromCodes;