import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";
const config = require("../Settings.json");

const HistoryEmp = () => {
    let sn = useParams();
    const [data, setData] = useState([]);
    const [dataOld, setOldData] = useState([]);
    useEffect(()=>{
        axios.post(config.DEV_URL + "/GetAssetHistory", {
            comp: sessionStorage.getItem("getCompany"),
            empName: sn.empFL
        }).then(res=>{
           
            if(res.data != "No Records Found"){
                setData(res.data);
            }
        }).catch(err => {
            console.log(err);
        })

        axios.post(config.DEV_URL + "/GetOldAssetHistory", {
            comp: sessionStorage.getItem("getCompany"),
            empName: sn.empFL
        }).then(res=>{
           
            if(res.data != "No Records Found"){
                setOldData(res.data);
            }

        }).catch(err => {
            console.log(err);
        })


    }, [])

 
    return(
    <div>
        <h2 className="pageTitle">Device History</h2>

        <div>
            <table className="ContentList">
        
        <tr>
        {sessionStorage.getItem("getCompany") === "Axia" ? <th>Device Ticket#</th> : null}
        {sessionStorage.getItem("getCompany") === "Axia" ? <th>Asset Ticket#</th> : null}
        <th>Device Name</th>
        <th>Device Status</th>
        <th>Serial Number</th>
        <th>Department</th>
        <th>Division</th>
        <th>Location</th>
        {sessionStorage.getItem("getCompany") == "Axia" ? <th>Company</th> : null}
        <th>Time Asset Given</th>
        <th>Asset Expiry</th>
        <th>Asset in Use?</th>
        <th>Deleted</th>
        </tr>

    {data ? data.map(list => (
        
        <tr key={list.AssetID ? list.AssetID : 1}>
            {sessionStorage.getItem("getCompany") === "Axia" ? <th>{list.deviceTicket}</th> : null}
            {sessionStorage.getItem("getCompany") === "Axia" ? <th>{list.assetTicket}</th> : null}
            <td>{list.AssetName ? list.AssetName : null }</td>
            <td>{list.DeviceStatus ? list.DeviceStatus : null}</td>
            <td>{list.SerialNumber ? list.SerialNumber : null}</td>
            <td>{list.OwnerDepartment ? list.OwnerDepartment : null}</td>
            <td>{list.OwnerDivision ? list.OwnerDivision : null}</td>
            <td>{list.OwnerLocation ? list.OwnerLocation : null}</td>
            {sessionStorage.getItem("getCompany") == "Axia" ? <td>{list.AssetComp}</td> : null}
            <td>{list.AssetTime ? (list.AssetTime.replace("T", " ").split(".0")[0]) : null}</td>
            <td>{list.AssetExpiry ? list.AssetExpiry : "No Date Set"}</td>
            <td>{list.AssetInUse == "true" ? "Yes" : list.AssetInUse == "false" ? "No" : null}</td>
            <td>Not Deleted</td>
        </tr>
    )) : null}

    {dataOld != "No Records Found" ? dataOld.map(list => (
        
        <tr key={list.AssetID ? list.AssetID : 1}>
            {sessionStorage.getItem("getCompany") === "Axia" ? <th>{list.deviceTicket}</th> : null}
            {sessionStorage.getItem("getCompany") === "Axia" ? <th>{list.assetTicket}</th> : null}
            <td>{list.AssetName ? list.AssetName : null }</td>
            <td>{list.assetStatus? list.assetStatus : null}</td>
            <td>{list.SerialNumber ? list.SerialNumber : null}</td>
            <td>{list.OwnerDepartment ? list.OwnerDepartment : null}</td>
            <td>{list.OwnerDivision ? list.OwnerDivision : null}</td>
            <td>{list.OwnerLocation ? list.OwnerLocation : null}</td>
            {sessionStorage.getItem("getCompany") == "Axia" ? <td>{list.AssetComp}</td> : null}
            <td>{list.AssetTime ? (list.AssetTime.replace("T", " ").split(".0")[0]) : null}</td>
            <td>{list.AssetExpiry ? list.AssetExpiry : "No Date Set"}</td>
            <td>{list.AssetInUse == "true" ? "Yes" : list.AssetInUse == "false" ? "No" : null}</td>
            <td>{list.Deleted ? list.Deleted : null}</td>
        </tr>
    )) : null}
    {/*
    data.map(list => (<tr><button value={list.DeviceID} onClick={e => handleDelete(e)}>Delete</button></tr>))}
    </td>*/}
    </table>
    </div>
    </div>
    );
}

export default HistoryEmp;