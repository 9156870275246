import axios from "axios";
import config from "../Settings.json";
import { publicIpv4 } from "public-ip";
const image = require("../../Images/CompanyLogos/Axia.png");
const { useState, useEffect } = require("react");

/*function generatePass(){
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@!*-_=+()&^%$£"#';
    let res = "";
    for(let i = 0; i < 20; i++){
        res += chars.charAt(Math.random() * chars.length);
    }
    return res;
}*/

let count = 0;

const Login =  () => {
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [error, setError] = useState("Please Enter Credientials");
    const [ipv4Res, setIPRes] = useState("");
    const [data, setData] = useState([]);
    const [sKey, setKey] = useState(null);
    const [inputKey, setInputKey] = useState("1");
    const [requiresAuthentication, setAuthStat] = useState(false);
    

    useEffect(() => {
        getIp();
    }, [requiresAuthentication])

    const getUsername = async(e) =>{
        setUsername(e.target.value);
        e.preventDefault();
    }
    const getPassword = (e) =>{
        setPassword(e.target.value);
        e.preventDefault();
    }

    const getIp = async() => {
        await publicIpv4().then(res => {
            setIPRes(res);
        })
    }

    const submission = async (e) =>{
        axios.post(config.DEV_URL + "/loginUser", {
            uName: username,
            uPass: password,
            IPv4Address: ipv4Res
        }).then(res => {
            if(res.data.length != 0 && res.data.map(i => i.authenticationEnabled) == 1){
                axios.put(config.DEV_URL + "/getAuthToken", {
                    userName: res.data.map(data => data.Username),
                    sKey: res.data.map(i => i.authenticatedKey),
                    arrData: res.data
                }).then(resToken => {
                    if(resToken){
                        window.location = "/authenticateUser/" + resToken.data;
                    }
                })
            }
            else if(res.data.length != 0){
                setAuthStat(false);
                let isLogged = true;
                let comp = res.data.map(data => data.Company);
                let userFlag = res.data.map(data => data.UserFlag);
                let userID = res.data.map(data => data.UserID);
                let username = res.data.map(data => data.Username);
                sessionStorage.setItem("getFlag", userFlag);
                sessionStorage.setItem("getCompany", comp);
                sessionStorage.setItem("isLogged", isLogged);
                sessionStorage.setItem("user?name", username);
                sessionStorage.setItem("userIdentity?id", userID);
                window.location = "/";
            }
            else{
                setError("Wrong Credientials");
            }
        }).catch(err => {
            setError("Error has occurred");
            console.log(err);
        })
        e.preventDefault();
    }

    const fPass = (e) => {
        window.location = "/forgot-password";
        e.preventDefault();
    }

    const fUser = (e) => {
        window.location = "/forgot-User";
        e.preventDefault();
    }

    let backImage = null;
    try{
    backImage = require("../../Images/backgroundImage.jpg");
    }
    catch{
        backImage = null;
    }


    function showPassword(){
        let pass = document.getElementById("showPass");
     
        if(pass.type === "password"){
           pass.type = "text";
        }
        else{
           pass.type = "password";
        }
     }
    return(
        
        <div>
             {/*backImage != null ? <img className="backgroundImage" src={backImage}></img>:null*/}
            {<div className="loginBackground"></div>}
            {<div className="loginBackground2"></div>}
            <div className="loginTitle"><h1>Axia LMS</h1></div>
            <div className="MainLogo"><img src={image}></img></div>
           
            <div className="loginSection">
            <form>
                <label value="Username"> Username</label> <br></br>
                <input type="text" onChange={e => getUsername(e)}></input><br></br>
                <label value="Password">Password</label><br></br>
                <input type="password" id="showPass" onChange={e => getPassword(e)}></input><br></br>
                <span><label>Show Password</label><br></br><input type="checkbox" onClick={showPassword}></input></span><br></br>
                <p>{error}</p>
                <button onClick={e => submission(e)}>Submit</button>
                <br></br>
                <button onClick={e => fPass(e)}>Forgot Password</button>
                <br></br>
                <button onClick={e => fUser(e)}>Forgot Username</button>
            </form>
            </div>
            <div className="loginFooter">
            <footer>
                Designed by Axia Design (An Axia Computer Systems Subsidiary)
            </footer>
            </div>
        </div>
    );
}

export default Login;