import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './SASS/CSS Files/Main.css';
import './SASS/CSS Files/Mobile.css';
import Login from "./Routes/Users/Login";
import ForgotPass from './Routes/Users/ForgotPass';
import ForgotUser from './Routes/Users/ForgotUser';
import GetEmployees from './Routes/Users/Employees';
import Nav from './Routes/Routes';
import GetDevices from './Routes/Devices/getListDevices';
import AddEmployee from './Routes/Users/AddEmployee';
import ComInfo from './Routes/CompanyDetails/compDetails';
import AddDevice from './Routes/Devices/addDevices';
import AddAssets from './Routes/AssetsDetails/addAssets';
import GetAssets from './Routes/AssetsDetails/getAssets';
import PrintAsset from './Routes/AssetsDetails/printAssets';
import UpdateEmployee from './Routes/Users/UpdateEmployee';
import UpdateAsset from './Routes/AssetsDetails/updateAssets';
import GetCompanies from './Routes/CompanyDetails/Companies';
import ErrorPage from './Routes/404';
import AddCompany from './Routes/CompanyDetails/AddCompany';
import UpdateCompany from './Routes/CompanyDetails/UpdateCompany';
import AddUser from './Routes/Users/AddUser';
import GetUsers from './Routes/Users/GetUsers';
import UpdateUser from './Routes/Users/UpdateUser';
import Settings from './Routes/Users/Settings';
import UpdateDevice from './Routes/Devices/updateDevice';
import HistoryDevices from './Routes/Devices/historyDev';
import GetAssetsReport from './Routes/AssetsDetails/getAssetReport';
import GetDevicesReport from './Routes/Devices/getListDevicesReport';
import GetEmployeesReport from './Routes/Users/EmployeesReport';
import ReportMenu from './Routes/ReportMenu';
import HistoryEmp from './Routes/Users/employeeHistory';
import GetDevicesPool from './Routes/Devices/getListDevicesPool';
import AssignbyID from './Routes/Devices/easyAssign';
import Dashboard from './Routes/Dashboard';
import DeviceDetails from './Routes/Devices/allDeviceContent';
import RegradeDevice from './Routes/Devices/regradeDevice';
import AssetsDetails from './Routes/AssetsDetails/getAssetsDetail';
import Barcode from './Routes/Devices/barcode';
import AssignedDevices from './Routes/Devices/getListDevicesAssigned';
import DisposableDev from './Routes/Devices/disposableDevices';
import DeviceFromCodes from './Routes/Devices/DeviceFromCodes';
import VerifyAuth from './Routes/Users/verifyAuth';
import FactorVerify from './Routes/Users/factorVerify';
import AuditTrail from './Routes/Admin/AuditTrail';


function App() {
  let check = sessionStorage.getItem("isLogged");
  let flagCheck = sessionStorage.getItem("getFlag");

  return (
    <Router>
      <Routes>
      <Route path="/forgot-password" element={!check ? <ForgotPass/> : <Login/>}/>
      <Route path="/forgot-user" element={!check ? <ForgotUser/> : <Login/>}/>
      <Route path="/authenticateUser/:tokenAuth" element={!check ? <FactorVerify/> : <Login/>}/>
      {<Route path='/DeviceDetailsBarcode/:deviceUniID' element={<DeviceFromCodes/>}/>}
       {<Route path='/barcode/:ID/:Type' element={!check ? <Login/> : <Barcode/>}/>}
        <Route exact path="/" element={!check ? <Login/> : <Nav/>}>
        <Route path="/" element={<Dashboard/>}/>
        <Route path="/Dashboard" element={<Dashboard/>}/>
        <Route path='*' element={!check ? <Login/> : <ErrorPage/>}/>
        <Route path="/EmployeeList" element={sessionStorage.getItem("getCompany") == "Axia" ? <GetEmployees/> : <ErrorPage/>}/>
        <Route path='/CurrentHardware' element={<GetDevices/>}/>
        <Route path='/CurrentHardwarePool' element={<GetDevicesPool/>}/>
        <Route path= '/AssignedDevices' element={<AssignedDevices/>}/>
        <Route path='/AssetsList' element={sessionStorage.getItem("getCompany") == "Axia" ? <GetAssets/> : <ErrorPage/>}/>
        <Route path='/DisposableDevices' element={<DisposableDev/>}/>
        {flagCheck == 1 || flagCheck == 0 ? <Route path='/Add-Employee' element={<AddEmployee/>}/> : <Route path='/Add-Employee' element={<ErrorPage/>}/>}
        <Route path='/Add-CompInfo' element={sessionStorage.getItem("getCompany") == "Axia" && flagCheck <= 1 ? <ComInfo/> : <ErrorPage/>}/>
        {flagCheck == 1 || flagCheck == 0 ? <Route path='/Add-Devices' element={<AddDevice/>}/> : <Route path='/Add-Devices' element={<ErrorPage/>}/> }
        {flagCheck == 1 || flagCheck == 0 ? <Route path='/Add-Assets' element={<AddAssets/>}/> : <Route path='/Add-Assets' element={<ErrorPage/>}/> }
        {flagCheck == 1 || flagCheck == 0 ? <Route path='/AssignDevice/:devID' element={<AssignbyID/>}/> : <Route path='/Add-Assets' element={<ErrorPage/>}/> }
        {sessionStorage.getItem("getCompany") == "Axia" ? <Route path='/Get-Companies' element={<GetCompanies/>}/> : <Route path='/Get-Companies' element={<ErrorPage/>}/>}
        {sessionStorage.getItem("getCompany") == "Axia" ? <Route path='/Add-Company' element={<AddCompany/>}/> : <Route path='/Add-Companies' element={<ErrorPage/>}/>}
        {sessionStorage.getItem("getCompany") == "Axia" ? <Route path='/Update-Company/:cID' element={<UpdateCompany/>}/> : <Route path='/Update-Companies' element={<ErrorPage/>}/>}
        {sessionStorage.getItem("getCompany") == "Axia" ? <Route path='/GetUsers' element={<GetUsers/>}/> : <Route path='/GetUsers' element={<ErrorPage/>}/>}
        {sessionStorage.getItem("getCompany") == "Axia" ? <Route path='/Add-User' element={<AddUser/>}/> : <Route path='/Add-User' element={<ErrorPage/>}/>}
        {sessionStorage.getItem("getCompany") == "Axia" ? <Route path='/Update-Users/:uID' element={<UpdateUser/>}/> : <Route path='/Update-Users' element={<ErrorPage/>}/>}
        {sessionStorage.getItem("getCompany") == "Axia" && flagCheck == 0 ? <Route path='/audit-trail' element={<AuditTrail/>}/> : <Route path='/audit-trail' element={<ErrorPage/>}/>}
        {flagCheck == 1 || flagCheck == 0 ? <Route path='/UpdateEmployee/:uID' element={<UpdateEmployee/>}/> : <Route path='/UpdateEmployee' element={<ErrorPage/>}/>}
        {flagCheck == 1 || flagCheck == 0 ? <Route path='/UpdatingAsset/:aID' element={<UpdateAsset/>}/> : <Route path='/UpdatingAsset' element={<ErrorPage/>}/>}
        {flagCheck == 1 || flagCheck == 0 ? <Route path='/AssetsContent/:aID' element={<AssetsDetails/>}/> : <Route path='/AssetsContent' element={<ErrorPage/>}/>}
        {flagCheck == 1 || flagCheck == 0 ? <Route path='/UpdateDevice/:dID' element={<UpdateDevice/>}/> : <Route path='/UpdateDevice' element={<ErrorPage/>}/>}
        {flagCheck == 1 || flagCheck == 0 ? <Route path='/RegradeDevice/:dID' element={<RegradeDevice/>}/> : <Route path='/RegradeDevice' element={<ErrorPage/>}/>}
        {flagCheck <= 2 ? <Route path='/historyDevice/:devSN' element={<HistoryDevices/>}/> : <Route path='/historyDevice' element={<ErrorPage/>}/>}
        {flagCheck <= 2 ? <Route path='/historyEmp/:empFL' element={<HistoryEmp/>}/> : <Route path='/historyEmp' element={<ErrorPage/>}/>}
        {flagCheck <= 2 ? <Route path='/fieldReport' element={<GetAssetsReport/>}/> : <Route path='/fieldReports' element={<ErrorPage/>}/>}
        {flagCheck <= 2 ? <Route path='/assetReport' element={<GetDevicesReport/>}/> : <Route path='/assetReports' element={<ErrorPage/>}/>}
        {flagCheck <= 2 ? <Route path='/employeesReport' element={<GetEmployeesReport/>}/> : <Route path='/employeesReports' element={<ErrorPage/>}/>}
        {flagCheck <= 2 ? <Route path='/ReportsMenu' element={<ReportMenu/>}/> : <Route path='/ReportsMenu' element={<ErrorPage/>}/>}
        {flagCheck <= 2 ? <Route path='/DeviceDetails/:dID' element={<DeviceDetails/>}/> : <Route path='/DeviceDetails/:dID' element={<ErrorPage/>}/>}
        {/*flagCheck <= 2 ? <Route path='/DeviceDetailsBarcode/:token' element={<DeviceFromCodes/>}/> : <Route path='/DeviceDetailsBarcode/:token' element={<ErrorPage/>}/>*/}
        {/*<Route path='/PrintAsset/:id' element={<PrintAsset/>}/>*/}
        <Route path='/Settings' element={<Settings/>}/>
        <Route path='/VerifyAuth/:sessToken' element={<VerifyAuth/>}/>
        
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
