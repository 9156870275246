import { useState, useEffect } from "react"
import axios from "axios";
import config from "../Settings.json";
import { publicIpv4 } from "public-ip";
const AddCompany = () => {

    const[companyName ,setCompName] = useState("");
    const[address ,setAddy] = useState("");
    const[address2 ,setAddy2] = useState("");
    const[postcode ,setPost] = useState("");
    const[phoneno, setNum] = useState("");
    const[town, setTown] = useState("");
    const[county, setCounty] = useState("");
    const[ipv4Addy, setIP] = useState("");
    const[changesArr] = useState({});

    async function getIP(){
      publicIpv4().then(res => {
         setIP(res);
      })
    }

    useEffect(() => {
      getIP();
    }, [])
    const getCompName = (e) => {
       setCompName(e.target.value);
       changesArr["Company Name"] = e.target.value;
       e.preventDefault(); 
    }

    const getAddy = (e) => {
        setAddy(e.target.value);
        changesArr["Address"] = e.target.value;
        e.preventDefault(); 
     }

     const getAddy2 = (e) => {
        setAddy2(e.target.value);
        changesArr["Address 2"] = e.target.value;
        e.preventDefault(); 
     }

     const getTown = (e) => {
      setTown(e.target.value);
      changesArr["Town"] = e.target.value;
      e.preventDefault(); 
     }

     const getCounty = (e) => {
      setCounty(e.target.value);
      changesArr["County"] = e.target.value;
      e.preventDefault(); 
     }

     const getPost = (e) => {
        setPost(e.target.value);
        changesArr["Post-Code"] = e.target.value;
        e.preventDefault(); 
     }

     const getPhone = (e) => {
        setNum(e.target.value);
        changesArr["Phone"] = e.target.value;
        e.preventDefault(); 
     }

     const handleSubmission = (e) => {
      e.preventDefault();
        axios.post(config.DEV_URL +"/addCompany", {
            cName: companyName,
            cAddress: address,
            cAddress2: address2,
            cPost: postcode,
            cPhone: phoneno,
            cTown: town,
            cCounty: county,
            addedBy: sessionStorage.getItem("user?name")
        }).then(res => {
         if(res != null){
            axios.put(config.DEV_URL + "/trailingChanges", {
               actionTaken: "Added Company",
               actionLoc: "Company Page",
               changeMade: JSON.stringify(changesArr),
               IPv4Addy: ipv4Addy,
               userName: sessionStorage.getItem("user?name")
           }).catch(err => {
               console.log(err);
           })
            window.location = "/Get-Companies";
         }
         else{
            window.alert("Entry Was not added, Re enter fields and try again");
         }
        }).catch(err => {
         console.log(err);
        })
     }


    return(
        <div>
         <div className="MainForms">
         <label className="formTitles">Add Company</label>
         <form onSubmit={e => handleSubmission(e)}>
            <br></br>
            <label>Enter Company Name *</label>
            <br></br>
            <div className="formEffects">
            <input type="text" required onChange={e=>getCompName(e)}></input>
            </div>
            <br></br>
            <label>Enter Address Line 1 *</label>
            <br></br>
            <div className="formEffects">
            <input type="text" required onChange={e=>getAddy(e)}></input>
            </div>

            <br></br>
            <label>Enter Address Line 2 (optional) </label>
            <br></br>
            <div className="formEffects">
            <input type="text" onChange={e=>getAddy2(e)}></input>
            </div>

            <br></br>
            <label>Enter Town/City </label>
            <br></br>
            <div className="formEffects">
            <input type="text" onChange={e=>getTown(e)}></input>
            </div>

            <br></br>
            <label>Enter County </label>
            <br></br>
            <div className="formEffects">
            <input type="text" onChange={e=>getCounty(e)}></input>
            </div>


            <br></br>
            <label>Enter Postcode </label>
            <br></br>
            <div className="formEffects">
            <input type="text" required onChange={e=>getPost(e)}></input>
            </div>
            
            <br></br>
            <label>Enter Phone Number </label>
            <br></br>
            <div className="formEffects">
            <input type="tel" minLength="11" maxLength="13"
            required onChange={e=>getPhone(e)}></input>
            </div>

            <button type="submit">
               Submit
            </button>
         </form>
         </div>
        </div>
    )
}


export default AddCompany;