import { useState, useEffect } from "react";
import config from "../Settings.json";
import axios from "axios";
import { useParams } from "react-router";

const PrintAsset = () => {
    const pid = useParams();
    const [data, setData] = useState([]);
    const [latestTime, setTime] = useState("");
    useEffect(() => {
        /*axios.post(config.DEV_URL + "/printAsset", {
        id: pid.id
        }).then(res => {
        if(res != null){
            console.log(res);
            setData(res.data);
        }
        else{
            console.log("no record");
        }
        }).catch(err => {
            console.log(err);
        })*/

        axios.get(config.DEV_URL + "/getImageDevSerial", {
            params:{
                assetID: pid.id
            }
        }).then(res => {
            setData(res.data);
            console.log(res);
        }).catch(err => {
            console.log(err);
        })
        getTime();
 }, [])

 function getTime(){
    setTime(new Date());
}

setTimeout(getTime, 1000);

 const handlePrint = (e) => {
   window.print();
 }

 return(
    <div className="PrintContainer">
         <button onClick={() => handlePrint()}>Print</button>
        <div className="PrintTable">

        <div className="TimeTop">
            <label>Time of document</label>
            <p>{latestTime.toString().split("GMT")[0]}</p>
            <br></br>
        </div>
        {data.map(list => list.SerialNumber != null 
                    ? <img src={require(`../../DeviceImages/${list.CompanyDevice}/${list.SerialNumber}/${list.ImageFileCondition}`)}/>
                    : <img src={require(`../../Images/placeholder.webp`)}/>)}
            <ul>
                <div className="sectionFlex">
                <div className="section1">
                <label>Current Asset Owner</label>
                {data.map(item => <p>{item.OwnerOfAsset}</p>)}
                <br></br>
                <label>Device Name</label>
                {data.map(item => <p>{item.AssetName}</p>)}
                <br></br>
                <label>Serial Number</label>
                {data.map(item => <p>{item.SerialNumber}</p>)}
                <br></br>
                <label>Grade</label>
                {data.map(item => <p>{item.ConditionOfDevice}</p>)}
                </div>

                <div className="section1">
                <label>Device Type</label>
                {data.map(item => <p>{item.AssetType}</p>)}
                <br></br>
                <label>Status</label>
                {data.map(item => <p>{item.StatusOfDevice}</p>)}
                <br></br>
                <label>Date Asset Was Given</label>
                {data.map(item => <p>{item.AssetTime.replace("T", " ").split(".0")[0]}</p>)}
                <br></br>
                <label>Is Asset Still In Use?</label>
                <p>{data.map(item => item.AssetInUse == "true" ? "Yes" : "No")}</p>
                <br></br>
                </div>
                </div>

            </ul>
        </div>
    </div>


 )

}

export default PrintAsset;