import config from "../Settings.json";
import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router";

const AssetsDetails = () => {
    const eID = useParams();
    const [data, setData] = useState([]);
    const [prevData, setPrev] = useState([]);

    useEffect(()=>{
        axios.post(config.DEV_URL + "/getAssetsByID", {
            Company: sessionStorage.getItem("getCompany"),
            assetID: eID.aID
        }).then(res => {
            if(res){
                setData(res.data);
                axios.put(config.DEV_URL + "/getPrev", {
                        owner: res.data.map(list => list.OwnerOfAsset)
                }).then(res => {
                        setPrev(res.data);
                })
            }
            else{
                setData([]);
            }
        }).catch(err => {
            console.log(err);
        })
    }, [])



    return(
        <div>
            <div className="MainForms">
            <div className="formLeft">
            <form>

            <label>Company</label>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(list => list.AssetComp)}></input>
            <br></br>
            </div>

            <label>User of Asset</label>
            <br></br>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(list => list.OwnerOfAsset)}></input>
            </div>
            <br></br>

            <label>User Location</label>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(list => list.OwnerLocation)}></input>
            <br></br>
            </div>
       
            <label>User Department</label>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(list => list.OwnerDepartment)}></input>
            <br></br>
            </div>

            <label>User Division</label>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(list => list.OwnerDivision)}></input>
            <br></br>
            </div>

            <label>Asset Device</label>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(list => list.AssetName)}></input>
            <br></br>
            </div>

            <label>Device Status</label>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(list => list.DeviceStatus)}></input>
            <br></br>
            </div>

            <label>Serial Number</label>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(list => list.SerialNumber)}></input>
            <br></br>
            </div>

            <label>Date Asset Given</label>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(list => list.AssetGivenTime)}></input>
            </div>
            <br></br>

            <label>Asset Expiry Date(optional)</label>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(list => list.AssetExpiry) != null ? data.map(list => list.AssetExpiry) : "No Expiry Date Set"}></input>
            </div>
            <br></br>

            <label>Is Asset Currently In Use?</label>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(list => list.AssetInUse == "true" ? "Yes" : list.AssetInUse == "false" ? "No" : null)}></input>
            </div>
            <br></br>

            <label>Type Of Asset</label>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(list => list.AssetType)}></input>
            </div>
            <br></br>

            <label>Notes</label>
            <div className="formEffects">
            <textarea style={{width:"580px", height:"300px", resize: "vertical"}} disabled placeholder={data.map(list => list.AssetNotes)}></textarea>
            </div>
            <br></br>
            
            </form>
            <table className="prevRecord">
                <thead>
                <tr><p>Previous Records</p></tr>
                    <tr>
                        <th>Employee Name</th>
                        <th>Division</th>
                        <th>Department</th>
                        <th>Location</th>
                        <th>Asset Device</th>
                        <th>Type Of Device</th>
                        <th>Is Asset In Use</th>
                        <th>Time Asset Added</th>
                        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Added By</th> : null}
                        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Last Updated</th> : null} 
                        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Updated By</th> : null} 
                        </tr>
                        </thead>
                    <tbody>
                        {prevData.map(list => (
                        <tr key={list.AssetID}>
                            <td>{list.OwnerOfAsset}</td>
                            <td>{list.OwnerDivision}</td>
                            <td>{list.OwnerDepartment}</td>
                            <td>{list.OwnerLocation}</td>
                            <td>{list.AssetName}</td>
                            <td>{list.AssetType}</td>
                            <td>{list.AssetInUse == "true" ? "Yes" : "No"}</td>
                            <td>{list.AssetGivenTime.split("T")[0]}</td>
                            {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td>{list.AddedBy}</td>  : null}
                            {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td>{list.AssetLastUpdate}</td> : null}
                            {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td>{list.UpdatedBy}</td> : null}
                            </tr>
                            ))}
                    </tbody>
                </table>
                
             </div>
            </div>
        </div>
    )
}

export default AssetsDetails;