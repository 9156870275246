import axios from "axios";
import { useEffect, useState } from "react";
import config from "../Settings.json";
import { publicIpv4 } from "public-ip";

const ComInfo = () =>{
    const [cData, setCData] = useState([]);
    const [depData, setDepData] = useState([]);
    const[HQData, setHQData] = useState([]);
    const[Division, setDivision] = useState([]);
    const [compDivision, setCDiv] = useState("");
    const [depart, setDepart] = useState("");
    const [CompName, setCName] = useState(sessionStorage.getItem("getCompany"));
    const [headQuarters, setHead] = useState("");
    const [Error, setError] = useState("");
    const [ipv4Addy, setIP] = useState("");
    const [changesArr] = useState({});
    let headQArr = [];
    let DepdataArr = [];
    let DivisionArr = [];

    async function getIP(){
        await publicIpv4().then(res => {
            setIP(res);
        })
    }

    useEffect(()=>{
        getIP();
        axios.put(config.DEV_URL + "/getInfo", {
            compName:sessionStorage.getItem("getCompany")
        }).then(res => {
            if(res.data != "results not found")
            {
            setDepData(new Set(res.data.map(item => item.CompanyDepartment)));
            setHQData(new Set(res.data.map(item => item.Headquarters)));
            setDivision(new Set(res.data.map(item => item.CompanyDivision)));
            }
            else
            {
                setDivision(null);
                setDepData(null);
                setHQData(null);
            }
        }).catch(err => {
            console.log(err);
        })

        axios.put(config.DEV_URL + "/getCompanies").then(res =>{
            setCData(res.data);
        }).catch(err =>{
            console.log(err);
        });

    }, []);

    
    const getDetails = (e) =>{
        axios.put(config.DEV_URL + "/getInfo", {
            compName:e.target.value
        }).then(res => {
            if(res.data == "results not found")
            {
                setDivision(null);
                setDepData(null);
                setHQData(null);
            }
            else
            {
                setDivision(new Set(res.data.map(item => item.CompanyDivision)));
                setDepData(new Set(res.data.map(item => item.CompanyDepartment)));
                setHQData(new Set(res.data.map(item => item.Headquarters)));
            }
            console.log(res);
        }).catch(err => {
            console.log(err);
        })
    }
    
    if(HQData != null){
        HQData.forEach((i , key) => {
           if(i != "" && i != " " && i != null){
        headQArr.push(<option key={key} value={i}>{i}</option>);
        }
        })
     }
        else{
        <option></option>
     }
     if(depData != null){
        depData.forEach((i , key) => {
           if(i != "" && i != " " && i != null){
              DepdataArr.push(<option key={key} value={i}>{i}</option>);
           }
        })
     }
     else{
        <option></option>
     }
     if(Division != null){
        Division.forEach((i , key) => {
           if(i != "" && i != " " && i != null){
           DivisionArr.push(<option key={key} value={i}>{i}</option>);
           }
        })
     }
     else{
        <option></option>
     }
    
    const getDepart = (e) =>{
        changesArr["Department"] = e.target.value;
        setDepart(e.target.value);
        e.preventDefault();
    }

      const getCDiv = (e) =>{
        changesArr["Division"] = e.target.value;
        setCDiv(e.target.value);
        e.preventDefault();
    }

    const getHeadQuarter = (e) =>{
        changesArr["Headquarters"] = e.target.value;
        setHead(e.target.value);
        e.preventDefault();
    }
    const getCName = (e) => {
        if(sessionStorage.getItem("getCompany") == "Axia"){
            changesArr["Company"] = e.target.value;
            setCName(e.target.value);
        }
        else{
            changesArr["Company"] = sessionStorage.getItem("getCompany");
            setCName(sessionStorage.getItem("getCompany"));
        }
        e.preventDefault();
    }

    const twoFunc = e => {
        getCName(e);
        getDetails(e);
    }

    const handleSubmission = (e) => {
        axios.post(config.DEV_URL + "/Add-info", {
            cName:CompName,
            dName:depart,
            headQ:headQuarters,
            divisionComp: compDivision
        }).then(res => {
            if(!res){
                setError("Was Not Submitted, Error in fields");
            }
            else{
                axios.put(config.DEV_URL + "/trailingChanges", {
                    actionTaken: `Updated Company: ${CompName}`,
                    actionLoc: "Adding Company Administration Details Page",
                    changeMade: JSON.stringify(changesArr),
                    IPv4Addy: ipv4Addy,
                    userName: sessionStorage.getItem("user?name")
                }).catch(err => {
                    console.log(err);
                })

                window.location.reload();
            }
        }).catch(err => {
            setError("Error, Contact Admin");
            console.log(err);
        })

        e.preventDefault();
    }

    return(
        <div>
          <div className="MainForms">
            <form onSubmit={e => handleSubmission(e)}>
                <label>Company Name</label>
                <br></br>
                {sessionStorage.getItem("getCompany") == "Axia" ? 
                <select onChange={e => twoFunc(e)} required>
                    <option value={sessionStorage.getItem("getCompany")}>{sessionStorage.getItem("getCompany")}</option>
                    {cData.map(list => <option value={list.CompanyName}>{list.CompanyName}</option>)}
                </select>
                : <input type="text" disabled placeholder={sessionStorage.getItem("getCompany")}></input>}
                <br></br>
                <br></br>
                <label>Department</label>
                <br></br>

                <div className="formEffects">
                <input type="text" list="dep" required onChange={e=>getDepart(e)}></input>
                  <datalist id="dep" onChange={e => getDepart(e)}>
                    {DepdataArr != null ? DepdataArr : null}
                  </datalist>
                </div>

                <br></br>
                
                <label>Location</label>

                <br></br>

                <div className="formEffects">
                <input type="text" list="headQ" required onChange={e => getHeadQuarter(e)}></input>
                  <datalist id="headQ" onChange={e => getHeadQuarter(e)}>
                  {headQArr != null ? headQArr : null}
                  </datalist>
                  </div>
                  
                <br></br>

                <label>Company Division</label>
                <br></br>
                <div className="formEffects">
                    <input type="text" list="divisionList" required onChange={e => getCDiv(e)}></input>
                    <datalist id="divisionList" onChange={e => getCDiv(e)}>
                        {DivisionArr != null ? DivisionArr : null}
                        </datalist>
                        </div>
                        <br></br>
                <p>{Error}</p>
                <br></br>
                <button type="submit" >Submit</button>
            </form>
          </div>
        </div>
    )
}

export default ComInfo;