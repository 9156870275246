import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";
const config = require( "../Settings.json");

const DeviceDetails = () =>{
    const ID = useParams();
    const [data, setData] = useState([]);
    const [prevData, setPrev] = useState([]);

    useEffect(()=>{
        axios.post(config.DEV_URL + "/GetDevicesByID", {
            devID: ID.dID,
            comp:sessionStorage.getItem("getCompany")
        }).then(res => {
                if(res){
                    setData(res.data);
                    axios.put(config.DEV_URL + "/getPrevByDevice", {
                    serialNum: res.data.map(list => list.SerialNumber)
                    }).then(res => {
                        setPrev(res.data);
                    })
                    setPrev([]);
                }
            })
    }, [])

    const getImageVal = (e) => {
        window.open(e.target.currentSrc);
     }



    return(
        <div>

        <div className="MainForms">
        <div className="formLeft">
         <form>
            <label>Manufacturer</label>
            <br></br>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(list => list.Manufacturer)}></input>
            </div>
            <br></br>

            {
            sessionStorage.getItem("getCompany") == "Axia" ?
            <div>
            <label>Ticket Number</label>
            <br></br>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(list => list.ticketNumber)}></input>
            </div>
            <br></br></div> : null
            }

            <label>Device Name</label>
            <br></br>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(list => list.DeviceName)}></input>
            </div>
            <br></br>

            <label>Equipment ID</label>
            <br></br>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(list => list.DeviceUniqueID)}></input>
            </div>
            <br></br>

            <div className="formEffects">
            <label>Serial Number</label>
            <br></br>
            <input type="text" disabled placeholder={data.map(list => list.SerialNumber)}></input>
            <br></br>
            </div>

            <br></br>
            <label>Condition Grade</label>
            <br></br>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(list => list.ConditionOfDevice)}></input>
            </div>

            <br></br>
            <label>Status of Device</label>
            <br></br>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(list => list.StatusOfDevice)}></input>
            </div>

            <div>
            <label>Is Wiped?</label>
            <br></br>
            <div className="formEffects">
            <input type="text" placeholder={data.map(list => list.isWiped == 1 ? "Yes" : list.isWiped == 0 ? "No" : "Yes")}></input>
            </div>
            <br></br></div>


            <label>Notes</label>
            <div className="formEffects">
                <textarea style={{width:"580px", height:"300px", resize: "vertical"}}  disabled placeholder={data.map(list => list.AdditionalNotes)}></textarea>
            </div>
            <br></br>
            </form>


            <table className="prevRecord">
                
                <thead>
                <tr><p>Previous Records</p></tr>
                    <tr>
                        {sessionStorage.getItem("getCompany") === "Axia" ? <th>Ticket#</th> : null}
                        <th>Employee Name</th>
                        <th>Division</th>
                        <th>Department</th>
                        <th>Location</th>
                        <th>Asset Device</th>
                        <th>Type Of Device</th>
                        <th>Is Asset In Use</th>
                        <th>Time Asset Added</th>
                        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Added By</th> : null}
                        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Last Updated</th> : null} 
                        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Updated By</th> : null} 
                        </tr>
                        </thead>
                    <tbody>
                        {prevData.map(list => (
                        <tr key={list.AssetID}>
                            {sessionStorage.getItem("getCompany") === "Axia" ? <th>{list.assetTicket}</th> : null}
                            <td>{list.OwnerOfAsset}</td>
                            <td>{list.OwnerDivision}</td>
                            <td>{list.OwnerDepartment}</td>
                            <td>{list.OwnerLocation}</td>
                            <td>{list.AssetName}</td>
                            <td>{list.AssetType}</td>
                            <td>{list.AssetInUse == "true" ? "Yes" : "No"}</td>
                            <td>{list.AssetGivenTime.split("T")[0]}</td>
                            {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td>{list.AddedBy}</td>  : null}
                            {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td>{list.AssetLastUpdate}</td> : null}
                            {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td>{list.UpdatedBy}</td> : null}
                            </tr>
                            ))}
                    </tbody>
                </table>

         </div>
         </div>
    </div>
    );
}

export default DeviceDetails;