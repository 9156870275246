import { useEffect, useState } from "react";
import axios from "axios";
import config from "../Settings.json";
import { publicIpv4 } from "public-ip";

function showPassword(){
    let pass = document.getElementById("showPass");
 
    if(pass.type === "password"){
       pass.type = "text";
    }
    else{
       pass.type = "password";
    }
 }
 

const Settings = () =>{
    const[password ,setPassword] = useState(null);
    const[data, setData] = useState([]);
    const[userEmail, setUemail] = useState("");
    const[msg, setMsg] = useState("");
    const[beforeData] = useState({});
    const[ipv4Addy, setIP] = useState("");

    const getIP = async () => {
        await publicIpv4().then(res => {
            setIP(res);
        })
    }

    useEffect(() => {
        getIP();
        axios.post(config.DEV_URL + "/getUserByID", {
            userID: sessionStorage.getItem("userIdentity?id")
        }).then(res => {
            setData(res.data);
            setUemail(res.data.map(item=>item.Email));
            let a = document.getElementById("2FACheck");
            if(res.data.map(i => i.authenticationEnabled) == 1){
                a.checked = true;
            }
            else{
                a.checked = false;
            }
            
        }).catch(err =>{
            console.log(err);
        })
    }, [])

    const getPass = (e) => {
        setPassword(e.target.value);
        beforeData["Password"] = "Password Changed";
        e.preventDefault(); 
     }

     const getEmail = (e) => {
        setUemail(e.target.value);
        beforeData["Prev Email"] = data.map(i => i.Email);
        beforeData["New Email"] = e.target.value;
        e.preventDefault(); 
     }

     const enable2FA = () => {
        let a = document.getElementById("2FACheck");
        let combo = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        let genPass = "";
        //if(user has not enabled 2FA already then do this)
        if(a.checked == true){
            if(window.confirm("Want to Enable 2 Factor Authentication?") == true){
               for(let i = 0; i < 15; i++){
                genPass += combo.charAt(Math.random() * combo.length);
               }
               axios.post(config.DEV_URL + "/authSessionToken", {
                Username: sessionStorage.getItem("user?name"),
                sessPass: genPass
               }).then(res => {
                window.location = `/VerifyAuth/${res.data}`;
               }).catch(err => {
                console.log(err);
               }) 
            }
            else{
                a.checked = false;
            }
        }
        else{
            if(window.confirm("This will deactivate 2 Factor authentication and you will have to reregister") == true){
                axios.post(config.DEV_URL + "/insertAuth", {
                    userID: data.map(i => i.UserID),
                    authKey: "",
                    authEnabled: false
                }).catch(errorAuth => {
                    console.log(errorAuth);
                })
            }
            else{
                a.checked = true;
            }
        }

        //else ask for password to disable if password is correct
     }
     const handleSubmission = (e) => {
        axios.put(config.DEV_URL + "/UpdateUsersSet", {
            uID: sessionStorage.getItem("userIdentity?id"),
            Pass: password,
            Email: userEmail
        }).then(res => {
                if(res){
                    setMsg("Successfully Changed Details");
                    axios.put(config.DEV_URL + "/trailingChanges", {
                        actionTaken: "Changed Detail",
                        actionLoc: "Settings Page",
                        changeMade: JSON.stringify(beforeData),
                        IPv4Addy: ipv4Addy,
                        userName: sessionStorage.getItem("user?name")
                    }).catch(err => {
                        console.log(err);
                    })
                }
                else{
                    setMsg("Problem in saving details");
                }
        }).catch(err => {
            console.log(err);
        })
        e.preventDefault();
     }

    return(
        <div>
        <div className="MainForms">
        <label className="formTitles">Update Password Or Email</label>
        <form onSubmit={e => handleSubmission(e)}>

           <br></br>
           <label>Username</label>
           <br></br>
           <div className="formEffects">
           <input type="text" disabled placeholder={data.map(item => item.Username)}></input>
           </div>

           <br></br>
           <label>Firstname</label>
           <br></br>
           <div className="formEffects">
           <input type="text" disabled placeholder={data.map(item => item.Firstname)}></input>
           </div>
           <br></br>

           <label>Lastname</label>
           <br></br>
           <div className="formEffects">
           <input type="text" disabled placeholder={data.map(item => item.Lastname)}></input>
           </div>
           <br></br>

           <label>Update Password</label>
           <br></br>
           <div className="formEffects">
           <input type="password" id="showPass" onChange={e=>getPass(e)}></input>
           <span>Show Password<input type="checkbox" onClick={showPassword}></input></span>
           </div>
           <br></br>

           <br></br>
           <label>Enter Email</label>
           <br></br>
           <div className="formEffects">
           <input type="email" onChange={e=>getEmail(e)} 
           placeholder={data.map(item => item.Email)}></input>
           </div>
           <p>{msg}</p>

           <div className="formEffects">
           <span>Enable 2FA<input type="checkbox" id="2FACheck" onClick={() => enable2FA()}></input></span>
           </div>
           <br></br>
           <button type="submit">
              Submit
           </button>
        </form>
        </div>
       </div>
    )
}

export default Settings;