import axios from "axios";
import config from "../Settings.json";
import { useEffect, useState } from "react";
import deleteIcon from "../buttonIcons/folder-delete-icon.png";
import updateIcon from "../buttonIcons/folder-refresh-icon.png";
import historyIcon from "../buttonIcons/history-line-icon.png";
import assignIcon from "../buttonIcons/male-add-icon.png";
import playIcon from "../buttonIcons/play-round-icon.png";
import barcodeIcon from "../buttonIcons/barcode-scan-icon.png";
import qrIcon from "../buttonIcons/qr-code-scan-icon.png";

const AssignedDevices = () => {
    const [data, setData] = useState(null);
    const [userData, setUserData] = useState([]);
    let arr = {};
    useEffect(() => {
        axios.post(config.DEV_URL + "/GetAssignedDev", {
            comp: sessionStorage.getItem("getCompany")
        }).then(res => {
            setUserData(res.data.assetResult);
            setData(res.data.result);
        }).catch(err => {
            console.log(err);
        })
    }, [])

    if(userData){
        for(let i = 0; i < userData.length; i++){
            arr[userData[i].SerialNumber] = userData[i].OwnerOfAsset;
        }
    }

    const filterData = (e) => {
        let filt = e.target.value;
        axios.post(config.DEV_URL + "/getDeviceFilterAssigned", {
            Company: sessionStorage.getItem("getCompany"),
            Filter: filt
        }).then(res => {
            setData(res.data);
        }).catch(err => {
            console.log(err);
        })
        e.preventDefault();
    }

    const searchFilter = (e) => {
        let filt = e.target.value;
        axios.post(config.DEV_URL + "/getDevicesSearchPool", {
            Company: sessionStorage.getItem("getCompany"),
            Filt: filt,
            DevAssign: 1 
        }).then(res => {
            setData(res.data);
        }).catch(err => {
            console.log(err);
        })
    }

    const getImageVal = (e) => {
        window.open(e.target.currentSrc);
     }

    const updateDevice = (e) => {
        let msg = ""
        if(e.target.value === " "){
            msg = window.alert("There are no records to update");
        }
        else{
                window.location = `/UpdateDevice/${e.target.value}`;
        }
     }
     const deviceHistory = (e) => {
        let msg = ""
        if(e.target.value === " "){
            msg = window.alert("There are no records to update");
        }
        else{
            axios.post(config.DEV_URL + "/GetAssetHistory", {
                comp: sessionStorage.getItem("getCompany"),
                SerialNum: e.target.value
            }).then(res=>{
                console.log(res);
                if(res.data == "No Records Found"){
                    axios.post(config.DEV_URL + "/GetOldAssetHistory", {
                        comp: sessionStorage.getItem("getCompany"),
                        SerialNum: e.target.value
                    }).then(res=>{
                        console.log(res);
                        if(res.data == "No Records Found"){
                            
                            msg = window.alert("There is no history of device usage");
                        }
                        else{
                                window.location = `/historyDevice/${e.target.value}`;
                        }
                    }).catch(err => {
                        console.log(err);
                    })
                }
                else{
                        window.location = `/historyDevice/${e.target.value}`;
                }
            }).catch(err => {
                console.log(err);
            })
        }
     }

    return(
        <>
    <div>
        <h2 className="pageTitle">Assigned Devices</h2>
        <div className="buttontoAdd">
        <span> 🔍 </span>
        <input type="search" id="searchVal" onChange={e => searchFilter(e)}>
        </input>
        </div>

        <select className="filterList" onClick={e => filterData(e)}>
            <option value={"ASC"}>Sort By Oldest</option>
            <option value={"DESC"} selected>Sort By Newest</option>
        </select>

        <div>
            <table className="ContentList">
        
        <tr>
        <th>Ticket Number</th>
        <th>Device Unique ID</th>
        <th>Current User</th>
        <th>Manufacturer</th>
        <th>Device</th>
        <th>Serial Number</th>
        {sessionStorage.getItem("getCompany") == "Axia" ? <th>Company</th> : null}
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Assigned By</th> : null}
        <th>Image Serial Number</th>
        <th>Image Condition</th>
        <th>Image Working</th>
        <th>Video</th>
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Last Updated</th> : null}
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Updated By</th> : null}  
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Update</th> : null}
        {sessionStorage.getItem("getFlag") <= 2 ? <th>History</th> : null}
        <th>Generate Barcode</th>
        <th>Generate QR Code</th>
        </tr>

    {data ? data.map((list) => (
        
        <tr key={list.DeviceID ? list.DeviceID : 1}>
            <td>{list.ticketNumber ? list.ticketNumber : "-"}</td>
            <td>{list.DeviceUniqueID ? list.DeviceUniqueID : "-"}</td>

            <td>{arr[list.SerialNumber] ? arr[list.SerialNumber] : ""}</td>

            <td>{list.Manufacturer ? <a href={"DeviceDetails/" + list.DeviceID}>{list.Manufacturer}</a> : null }</td>
            
            <td>{list.DeviceName ? <a href={"DeviceDetails/" + list.DeviceID}>{list.DeviceName}</a> : null }</td>
            
            <td>{list.SerialNumber ? <a href={"DeviceDetails/" + list.DeviceID}>{list.SerialNumber} </a> : null}</td>
            {sessionStorage.getItem("getCompany") == "Axia" ? <td>{list.CompanyDevice}</td> : null}
            {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td>{list.AddedBy}</td> : null}
            <td className="imagePad">
            {list.SerialNumber != null && list.ImageFileSerialNum != null
            ? <a href="#" onClick={e => getImageVal(e)}><img src={require(`../../DeviceImages/${list.CompanyDevice}/${list.SerialNumber}/${list.ImageFileSerialNum}`)}/></a>
            : <img src={require(`../../Images/placeholder.webp`)}/>}</td>
            <td className="imagePad">
            {list.SerialNumber != null && list.ImageFileCondition !=null
            ? <a href="#" onClick={e => getImageVal(e)}><img src={require(`../../DeviceImages/${list.CompanyDevice}/${list.SerialNumber}/${list.ImageFileCondition}`)}/></a>
            : <img src={require(`../../Images/placeholder.webp`)}/>}</td>
            <td className="imagePad">
            {list.SerialNumber != null && list.ImageFileInUse != null
            ? <a href="#" onClick={e => getImageVal(e)}><img src={require(`../../DeviceImages/${list.CompanyDevice}/${list.SerialNumber}/${list.ImageFileInUse}`)}/></a> 
            : <img src={require(`../../Images/placeholder.webp`)}/>}</td>
            <td className="imagePad">
            {list.videoOfDevice != null
            ? <a href="#" onClick={e => getImageVal(e)}><video poster={playIcon} src={require(`../../DeviceImages/${list.CompanyDevice}/${list.SerialNumber}/${list.videoOfDevice}`)}></video></a> 
            : <img src={require(`../../Images/placeholder.webp`)}/>}</td>
            {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ?<td>{list.lastUpdated}</td> : null}
            {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td>{list.UpdatedBy}</td> : null}
            

            {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td><button className="tableButton" value={list.DeviceID ? list.DeviceID : " "} onClick={e => updateDevice(e)} title="Update Device"><img src={updateIcon}></img></button></td> : null}
            {sessionStorage.getItem("getFlag") <= 2 ? <td><button className="tableButton" value={list.SerialNumber ? list.SerialNumber : " "} onClick={e => deviceHistory(e)} title="Device History"><img src={historyIcon}></img></button></td> : null}
            <td><button className="tableButton" onClick={() => window.open(`/barcode/${list.DeviceUniqueID}/barcode`, "_blank", "width=800,height=800, titlebar=0, menubar=0, top=100, left=100")}><img src={barcodeIcon}></img></button></td>
            <td><button className="tableButton" onClick={() => window.open(`/barcode/${list.DeviceUniqueID}/qr`, "_blank", "width=800,height=800, titlebar=0, menubar=0, top=100, left=100")}><img src={qrIcon}></img></button></td>
        </tr>
    )) : <h1>No Devices In List</h1>}
    {/*
    data.map(list => (<tr><button value={list.DeviceID} onClick={e => handleDelete(e)}>Delete</button></tr>))}
    </td>*/}
    </table>
    </div>
    </div>
    </>
    )
}

export default AssignedDevices;