import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import config from "./Settings.json" ;
import axios from "axios";

const Dashboard = () => {
    const[data, setData] = useState([]);
    const[dataAsset, setAssetData] = useState([]);
    const[countDevice, setCountDev] = useState("0");
    const[countPoolDevice, setPoolDev] = useState("0");
    const[countAsset, setCountAsset] = useState("0");
    useEffect(()=>{
        axios.post(config.DEV_URL + "/GetDevicesDash", {
            comp: sessionStorage.getItem("getCompany")
        }).then(res => {
            
            if(res){
                setData(res.data);
            }
            else{
                setData([]);
            }
        }).catch(err => {
            //console.log(err);
        })

        axios.post(config.DEV_URL + "/GetAssetsDash", {
            comp: sessionStorage.getItem("getCompany")
        }).then(res => {
            
            if(res){
                setAssetData(res.data);
            }
            else{
                setAssetData([]);
            }
        }).catch(err => {
            //console.log(err);
        })

        axios.post(config.DEV_URL + "/GetDevicesCount", {
            comp: sessionStorage.getItem("getCompany")
        }).then(res => {
            setCountDev(res.data.map(item => item.countDev));
        }).catch(err => {
            //console.log(err);
        })

        /*axios.post(config.DEV_URL + "/GetUnAssignedCount", {
            comp: sessionStorage.getItem("getCompany")
        }).then(res => {
            setCountDev(res.data.map(item => item.countAsset));
        }).catch(err => {
            //console.log(err);
        })*/

        axios.post(config.DEV_URL + "/GetAssetsCount", {
            comp: sessionStorage.getItem("getCompany")
        }).then(res => {
            setCountAsset(res.data.map(item => item.countAsset));
        }).catch(err => {
            //console.log(err);
        })

        axios.post(config.DEV_URL + "/GetDevicesPoolCount", {
            comp: sessionStorage.getItem("getCompany")
        }).then(res => {
            setPoolDev(res.data.map(item => item.countPool));
        }).catch(err => {
            //console.log(err);
        })
    }, [])

    return(
        <div>
        <label className="WelcomeClass">
        Welcome {sessionStorage.getItem("user?name")}
        </label>
        <div className="DashboardClass">
            <div className="sectionFlex">
                <div className="section1">
                    <label>Number Of Assigned Devices</label>
                    <span className="countClass">{countAsset}</span>
                    
                </div>
                <div className="section1">
                    <label>Number of Devices Within the Pool</label>
                    <span className="countClass">{countPoolDevice}</span>
                </div>

                <div className="section1">
                    <label>Total Devices</label>
                    <span className="countClass">{countDevice}</span>
                </div>
            </div>

            <div className="sectionFlexRow">
                <div className="section1">
                    <label>Latest Device Added</label>
                    <table>
                        <div className="dashboardTable">
                        <tr>
                            <th>Device Name</th>
                            <th>Serial Number</th>
                            <th>Condition Of Device</th>
                            <th>Device Status</th>
                            <th>Added by</th>
                        </tr>
                        {data.map(result =>(
                            <tr>
                            <td>{result.DeviceName}</td>
                            <td>{result.SerialNumber}</td>
                            <td>{result.ConditionOfDevice}</td>
                            <td>{result.StatusOfDevice}</td>
                            <td>{result.AddedBy}</td>
                            </tr>
                        ))}
                        </div>
                    </table>
                </div>
                
                <div className="section1">
                    <label>Latest Assigned</label>
                    <table>
                    <div className="dashboardTable">
                        <tr>
                            <th>Owner Name</th>
                            <th>Device</th>
                            <th>Serial Number</th>
                            <th>Asset In Use</th>
                            <th>Asset Type</th>
                            <th>Added By</th>
                        </tr>
                        {dataAsset.map(result =>(
                            <tr>
                            <td>{result.OwnerOfAsset}</td>
                            <td>{result.AssetName}</td>
                            <td>{result.SerialNumber}</td>
                            <td>{result.AssetInUse}</td>
                            <td>{result.AssetType}</td>
                            <td>{result.AddedBy}</td>
                            </tr>
                        ))}
                        </div>
                    </table>
                </div>
            </div>

            <div className="sectionFlexRow">
                <div className="section1">
                    <label>Field</label>
                    <Link to="/AssetsList"><button>List Field Devices</button></Link>
                    {sessionStorage.getItem("getFlag") <= 1 ? <Link to="/add-assets"><button>Add Into Field</button></Link> : null}
                    <Link to="/fieldReport"><button>Field Report</button></Link>
                </div>
                <div className="section1">
                    <label>Devices</label>

                    <Link to="/CurrentHardwarePool"><button>List Of Pool</button></Link>
                    {sessionStorage.getItem("getFlag") <= 1 ? <Link to="/Add-Devices"><button>Add Devices</button></Link> : null}
                    <Link to="/assetReport"><button>Device Report</button></Link>
                </div>
                <Outlet/>
                </div>
        </div>
        </div>
    )
}

export default Dashboard;