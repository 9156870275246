import { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import config from "../Settings.json";
import { publicIpv4 } from "public-ip";
const UpdateEmployee = () => {
    const eID = useParams();
    const [data, setData] = useState([]);
    const [compData, setCompData] = useState([]);
    const[department ,setDepartment] = useState("");
    const[division ,setDivision] = useState("");
    const[loc ,setLoc] = useState("");
    const[Status ,setStatus] = useState("");
    const[ipv4Addy, setIP] = useState("");
    const[changesArr] = useState({});

    async function getIP(){
        await publicIpv4().then(res => {
            setIP(res);
        })
    }
    useEffect(()=>{
        getIP();

        axios.post(config.DEV_URL + "/getEmployeesByID", {
            Company: sessionStorage.getItem("getCompany"),
            uID: eID.uID
        }).then(res => {
            if(res){
                setData(res.data);
                setStatus(res.data.map(item => item.EmpStatus));
                setDepartment(res.data.map(item => item.EmpDepartment));
                setLoc(res.data.map(item => item.EmpLocation));
                setDivision(res.data.map(item => item.EmpDivision));
                
                axios.put(config.DEV_URL + "/getInfo", {
                    compName:res.data.map(item => item.EmpCompany)
                }).then(res2 => {
                    setCompData(res2.data);
                }).catch(err => {
                    console.log(err);
                })
            }
            else{
                setData([]);
            }
        }).catch(err => {
            console.log(err);
        })
    }, [])

    const getDepart = (e) => {
        changesArr["Prev-Depart"] = data.map(i => i.EmpDepartment);
        changesArr["New-Depart"] = e.target.value;
        setDepartment(e.target.value);
        e.preventDefault(); 
     }

     const getLocation = (e) => {
        changesArr["Prev-Location"] = data.map(i => i.EmpLocation);
        changesArr["New-Location"] = e.target.value;
        setLoc(e.target.value);
        e.preventDefault(); 
     }
     const getDivision = (e) => {
        changesArr["Prev-Division"] = data.map(i => i.EmpDivision);
        changesArr["New-Division"] = e.target.value;
        setDivision(e.target.value);
        e.preventDefault(); 
     }
     const getStatus = (e) => {
        changesArr["Prev-Status"] = data.map(i => i.EmpStatus);
        changesArr["New-Status"] = e.target.value;
        setStatus(e.target.value);
        e.preventDefault(); 
     }

     const handleSubmission = (e) => {
        axios.put(config.DEV_URL + "/updateEmployees", {
            Department: department,
            Location: loc,
            empStatus: Status,
            empAddedBy: sessionStorage.getItem("user?name"),
            id: eID.uID,
            empDiv: division,
            Company: sessionStorage.getItem("getCompany")
        }).then(res => {
             if(res){
                axios.put(config.DEV_URL + "/trailingChanges", {
                    actionTaken: `Updated Employee: ${data.map(i => i.EmpFirstName + " " + i.EmpLastName)}`,
                    actionLoc: "Employee Update Page",
                    changeMade: JSON.stringify(changesArr),
                    IPv4Addy: ipv4Addy,
                    userName: sessionStorage.getItem("user?name")
                }).catch(err => {
                    console.log(err);
                })
                window.location = "/EmployeeList";
             }
             else{
                window.alert("Some Information Was Incorrect");
             }
        }).catch(err => {
            console.log(err);
            window.alert("Error");
        })
        e.preventDefault();
     }
    
    
    return(
        <div>
        <div className="MainForms">
         <label className="formTitles">{data.map(list => "Updating Employee: " + list.EmpFirstName + " " + list.EmpLastName)}</label>
         <form onSubmit={e => handleSubmission(e)}>
            
            <label>Firstname</label>
            <br></br>
            <div className="formEffects">
            <input type="text" disabled placeholder={data.map(list => list.EmpFirstName)}
            value={data.map(list => list.EmpFirstName)}></input>
            </div>
            <br></br>
            <label>Lastname</label>
            <br></br>
            <div className="formEffects">
            <input type="text" required disabled placeholder={data.map(list => list.EmpLastName)}
            value={data.map(list => list.EmpLastName)}></input>
            </div>
            <label>Department</label>
            <br></br>

            <div className="formEffects">
            <select onChange={e => getDepart(e)}>
            {data.map(item => <option disabled selected>{item.EmpDepartment}</option>)}
            {(compData !== "results not found" ? compData.map(item => 
            (item.CompanyDepartment != "" ? <option value={item.CompanyDepartment}>
            {item.CompanyDepartment}</option> : null)) : null)}
            </select>
            </div>
            <br></br> 
              
            <div className="formEffects">
            <label>Headquarters</label>
            <br></br>
            <select onChange={e => getLocation(e)}>
            {data.map(item => <option disabled selected>{item.EmpLocation}</option>)}
            {(compData !== "results not found" ? compData.map(item => 
            (item.Headquarters != "" ? <option value={item.Headquarters}>
            {item.Headquarters}</option> : null)): null)}
            </select>
            </div>

            <div className="formEffects">
            <label>Division</label>
            <br></br>
            <select onChange={e => getDivision(e)}>
            {data.map(item => <option disabled selected>{item.EmpDivision}</option>)}
            {(compData !== "results not found" ? compData.map(item => 
            (item.CompanyDivision != "" ? <option value={item.CompanyDivision}>
            {item.CompanyDivision}</option> : null)): null)}
            </select>
            </div>

            <br></br>
            <label>Status</label>
            <br></br>
            <div className="formEffects">
            <select onChange={e => getStatus(e)}>
            {data.map(item => <option selected>{item.EmpStatus == "Contract" ? "Contractor" : item.EmpStatus == "Permenant" ? "Office Worker" : item.EmpStatus == "Remote" ? "Remote Worker" : item.EmpStatus}</option>)}
               <option value="Contract">Contractor</option>
               <option value="Permenant">Office Worker</option>
               <option value="Remote">Remote Worker</option>
               <option value="Not Working">Left</option>
            </select>
            </div>
            
            <button type="submit">
               Submit
            </button>
            </form>
        </div>
        </div>
    )
}

export default UpdateEmployee;