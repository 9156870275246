import { createElement, useEffect, useState } from "react";
import axios from "axios";
import config from "../Settings.json";
import { publicIpv4 } from "public-ip";
const AuditTrail = () => {
    const [data, setData] = useState([]);
    const [tToken, setToken] = useState([]);
    const [ip, setIP] = useState("");

    const getIP = async () => {
        await publicIpv4().then((res) => {
            setIP(res);
        });
    }

    useEffect(() => {
        if(ip == ""){
            getIP();
        }
        else{
            axios.post(config.DEV_URL + "/getAuditTrailAndToken", {
                Comp: sessionStorage.getItem("getCompany"),
                IPv4Addy: ip,
                userName: sessionStorage.getItem("user?name"),
                actionTaken: "Print Excel",
                actionLoc: "Audit-Trail Page"
            }).then((result) => {
                setData(result.data.trailRes);
                setToken(result.data.trailToken);
            }).catch(err => {
                console.log(err);
            })
        }
    }, [ip])

    const handle = ()=>{
        let a = document.createElement("a");
        a.href = config.DEV_URL +  "/getToken/" + tToken;
        a.download = 'filename.fileextension';
        a.click();
    }

    const getDetails = (e) => {
        let str = e.target.value;
        str = str.replace(/{|}/gi, "");
        str = str.replace(/"|"/gi, "");
        str = str.replace(/[\[\]']+/g, "");
        str = str.split(",").join("<br/>");
        str = str.split(":").join(":  ");
        let tab = window.open("", "Details", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=880,height=400");
        tab.document.body.innerHTML = `<h2 style="text-align:center;">Changes Made</h2>
        <br><br>
        <b><p style="font-size: 20px;">${str}</p></b>`;
    }

    const filterRes = (e) => {
        axios.post(config.DEV_URL + "/getAuditTrailFilt", {
            Comp: sessionStorage.getItem("getCompany"),
            Filt: e.target.value
        }).then(res => {
            setData(res.data);
        }).catch(err => {
            console.log(err);
        })
    }
    return(
        
        <div>
        <h2 className="pageTitle">Trail</h2>
        <div className="buttontoAdd">
            <button onClick={() => handle()}>Print Excel Sheet</button>
        <span> 🔍 </span>
        <input type="search" id="searchVal" onChange={e => filterRes(e)}> 
        </input>
        </div>

        {<select className="filterList">
            <option value={"ASC"} >Sort By Oldest</option>
            <option value={"DESC"} selected >Sort By Newest</option>
        </select>}

    <table className="ContentList">
    <thead>
        <tr>
        <th>User</th>
        <th>IP Address</th>
        <th>Action</th>
        <th>Location</th>
        <th>Change (if any)</th>
        <th>Time Logged</th>
        </tr>
        </thead>
    <tbody>


    {data.map(list => (
    <tr>
    <td>{list.userLogin}</td>
    <td>{list.publicIP}</td>
    <td>{list.actionTaken}</td>
    <td>{list.actionLocation}</td>
    <td>{list.changeAdded != "" && list.changeAdded != null ? <button value={list.changeAdded} onClick={(e) => getDetails(e)} >Details</button> : "-"}</td>
    <td>{list.timeLog}</td>
    
    </tr>
    ))}
    </tbody>
    </table>
    </div>
    )
}

export default AuditTrail;