import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";


const Nav = () => {
    const [time, setTime] = useState("");
    useEffect(() => {
        getTime();
    }, [])
    function getTime(){
        setTime(new Date());
    }

    setTimeout(getTime, 1000);
    let count = 0;
    let logo = null;
    let backImage = null;
    try{
    logo = require(`../Images/CompanyLogos/${sessionStorage.getItem("getCompany")}.png`);
    }
    catch{
        logo = null;
    }

    try{
    backImage = require("../Images/backgroundImage2.jpg");
    }
    catch{
        backImage = null;
    }

    function easterEggFunc(){
        count++;
        if(count == 10){
            window.alert("STOP CLICKING THE LOGO, IT WONT DO ANYTHING");
        }
        else if(count == 20){

            if(window.confirm("Alright, press okay to go to your company page :(") == true ){
                let c = 1;
                while(c != 10){
                c++
                window.alert("You actually press ok? well uh, dont know what else to put here.");  
                if(c % 2 == 0){
                    window.alert("CTRL + F5 is your best bet mate. or wait till I get bored or turn off the tab, hehehehehe, told you not to click it!");
                 }
                } 
            }
            else{
                let c = 1;
                while(c != 30){
                c++
                window.alert("There are " + c + " Apples on the tree");  
                if(c % 5 == 0){
                    window.alert("CTRL + F5 is your best bet mate. or wait till you got 30 abbles or turn off tab, hehehehehe, told you not to click it!");
                 }
                } 
            }
        }
    }

    const loggingOut = (e) =>{
        
        if(window.confirm("Do you wish to log out?") == true){
            logout();
        }
        else{
            
        }
        e.preventDefault();
    }
    function logout(){
        sessionStorage.clear();
        window.location = "/";
    }

    return(
        <div>
            {logo != null ? <a href="/Dashboard">
            <img className="compLogo" src={logo}></img></a> : null} 
            

            <div className="NavContainer">
                <div className="NavigationBar">
                    <div className="menuSidebar">
                        {backImage != null ? <img className="backgroundImage" src={backImage}></img>:null}
                        {sessionStorage.getItem("getFlag") == 0 && sessionStorage.getItem("getCompany") == "Axia" ? <Link to="/EmployeeList"><p>Employees</p></Link> : null}
                        <Link to="/CurrentHardware"><p>Devices</p></Link>
                        <Link to="/CurrentHardwarePool"><p>Pool</p></Link>
                        {sessionStorage.getItem("getFlag") == 0 && sessionStorage.getItem("getCompany") == "Axia" ? <Link to="/AssetsList"><p>Field</p></Link> : null}
                        <Link to="/AssignedDevices"><p>Assigned</p></Link>
                        <Link to="/DisposableDevices"><p>Disposed</p></Link>
                        {sessionStorage.getItem("getFlag") == 0 && sessionStorage.getItem("getCompany") == "Axia" ? <Link to="/add-compInfo"><p>Administration</p></Link> : null}
                        {sessionStorage.getItem("getCompany") == "Axia" && sessionStorage.getItem("getFlag") == 0 ? <Link to="/Get-Companies"><p>Companies</p></Link> : null}
                        {sessionStorage.getItem("getCompany") == "Axia" && sessionStorage.getItem("getFlag") == 0 ? <Link to="/getUsers"><p>Users</p></Link> : null}
                        {sessionStorage.getItem("getFlag") <= 2 ? <Link to="/ReportsMenu"><p>Reports</p></Link> : null}
                        {sessionStorage.getItem("getCompany") == "Axia" && sessionStorage.getItem("getFlag") == 0 ? <Link to="/audit-trail"><p>Audit-Trail</p></Link> : null}

                        <Link to="/Settings"><p id="GearRotate" style={{marginRight: "-5px"}}>⚙️</p></Link>
                        <Link to="#" onClick={e => loggingOut(e)}><p style={{marginRight: "5px"}}>❎</p></Link>
                    </div>
                    <div className="menuContainer">
                        <div className="userTime">{time.toString().split("GMT")[0]}</div>
                    </div>
                </div>
            
            </div>

            <Outlet/>

        </div>
    );
}

export default Nav;