import axios from "axios";
import { useEffect, useState } from "react";
import config from "../Settings.json";
import { useParams } from "react-router";
import deleteIcon from "../buttonIcons/folder-delete-icon.png";
import updateIcon from "../buttonIcons/folder-refresh-icon.png";
import historyIcon from "../buttonIcons/history-line-icon.png";
import { publicIpv4 } from "public-ip";
const GetEmployees = () => {
const[data, setData] = useState([]);
const[compData, setCompData] = useState([]);
const[pages, setNewPages] = useState(1);
const[ipv4Add, setIP] = useState("");
const pageButtons = [];

async function getIP(){
    await publicIpv4().then(res => {
        setIP(res);
    })
}
useEffect(() =>{
    getIP();
    axios.get(config.DEV_URL + "/getEmployees", {
        params:{
            Comp: sessionStorage.getItem("getCompany"),
        }
    }).then(res =>{
        setData(res.data.result);
        setNewPages(res.data.numberOfPages);
        //console.log(res.data);
    }).catch(err =>{
        console.log(err);
    });

    if(sessionStorage.getItem("getCompany") == "Axia"){
        axios.put(config.DEV_URL + "/getCompanies").then(res =>{
            setCompData(res.data);
            //console.log(res.data);
        }).catch(err =>{
            console.log(err);
        });
    }
    
}, []);

for(let i = 1; i <= pages; i++){
    pageButtons.push(i);
}


const getNewData = (e) =>{
    axios.post(config.DEV_URL +"/getEmployeesByID", {
        Company:sessionStorage.getItem("getCompany"),
        uID :e.target.value
    }).then(resGet => {
        axios.put(config.DEV_URL + "/trailingChanges", {
            userName: sessionStorage.getItem("user?name"),
            actionTaken: "Deleting Employee",
            actionLoc: "Employee Page",
            changeMade: JSON.stringify(resGet.data),
            IPv4Addy: ipv4Add
         }).catch(err => {
            console.log(err);
         })
        axios.post(config.DEV_URL + "/addEmployeesToRecycle", {
            uID:e.target.value,
            fName:resGet.data.map(item => item.EmpFirstName),
            lName:resGet.data.map(item => item.EmpLastName),
            Company:resGet.data.map(item => item.EmpCompany),
            Department:resGet.data.map(item => item.EmpDepartment),
            Location:resGet.data.map(item => item.EmpLocation),
            Division:resGet.data.map(item => item.EmpDivision),
            empDelBy:sessionStorage.getItem("user?name")
        }).then(res => {
            console.log(res.data);
        }).catch(err => {
            console.log(err);
        });
    }).catch(err => {
            console.log(err);
    });
}

const handleDelete = async (e) =>{
    if(window.confirm("Do you want to delete Employee ID: " + e.target.value) === true){
        getNewData(e);
        axios.put(config.DEV_URL +"/DeleteEmployee", {
        eID: e.target.value,
        Comp: sessionStorage.getItem("getCompany")
    }).then(res => {
        if(res == null){
            console.log("Invalid ID");
        }
        else{
            window.location.reload(); 
            //console.log(res);
        }
    }).catch(err => {
        console.log(err);
    })
  }
  else{
    console.log("Aborted");
  }
}

const addEmployee = (e) => {
    window.location = "/add-employee";
}

const filterData = (e) =>{

    axios.put(config.DEV_URL + "/getEmployeesFilter", {
        Company:sessionStorage.getItem("getCompany"),
        Filter: e.target.value
    }).then(res =>{
        setData(res.data);
        //console.log(res.data);
    }).catch(err =>{
        console.log(err);
    });
  }

  const updateEmployee = (e) => {
        window.location = `/UpdateEmployee/${e.target.value}`;
 }


 const historyEmployee = (e) => {
    let msg = ""
    if(e.target.value === " "){
        msg = window.alert("There are no records to update");
    }
    else{
        axios.post(config.DEV_URL + "/GetAssetHistory", {
            comp: sessionStorage.getItem("getCompany"),
            empName: e.target.value
        }).then(res=>{
            //console.log(res.data);
            if(res.data == "No Records Found"){
                axios.post(config.DEV_URL + "/GetOldAssetHistory", {
                    comp: sessionStorage.getItem("getCompany"),
                    empName: e.target.value
                }).then(res2=>{
                    console.log(res2.data);
                    if(res2.data == "No Records Found"){
                        msg = window.alert("There is no history of device usage for this employee");
                    }
                    else{
                            window.location = `/historyEmp/${e.target.value}`;
                    }
                }).catch(err => {
                    console.log(err);
                })
            }
            else{
                    window.location = `/historyEmp/${e.target.value}`;
            }
        }).catch(err => {
            console.log(err);
        })
    }
 }

 const getSearchVal = (e) => {
    searchList(e);
    /*let onClickFunc = document.getElementById("searchVal");
    if(onClickFunc.addEventListener("keypress", function(c){
        if(c.key === "Enter"){
            searchList(e);
            c.stopImmediatePropagation();
        }
    }))*/
    e.preventDefault();
}

 const searchList = (e) => {
    axios.post(config.DEV_URL + "/getEmpSearch", {
        Filt: e.target.value,
        Comp: sessionStorage.getItem("getCompany")
    }).then(res =>{
        setData(res.data);
        //console.log(res.data);
    }).catch(err =>{
        console.log(err);
    });
}

  const pageTing = (e) => {
    axios.get(config.DEV_URL + "/getEmployees", {
        params:{
            Comp: sessionStorage.getItem("getCompany"),
            PageNum:e.target.value
        }
    }).then(res =>{
        if(res != null){
            setData(res.data.result);
        }
        else{
            setData(null);
        }
    }).catch(err =>{
        console.log(err);
    });
  }

return(
    <div>
        
        
        <h2 className="pageTitle">Employees</h2>
        <div className="buttontoAdd">
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <button onClick={() => addEmployee()}>Add An Employee</button> : null}
        <span> 🔍 </span>
        <input type="search" id="searchVal" onChange={e => getSearchVal(e)}>
        </input>
        </div>

        <select className="filterList" onClick={e => filterData(e)}>
            <option value={"ASC"} >Sort By Oldest</option>
            <option value={"DESC"} selected >Sort By Newest</option>
            <option value={"Department"}>Sort By Department</option>
            <option value={"Working"}>Sort by Working</option>
            <option value={"Gone"}>Sort By Fired/Left</option>
            {sessionStorage.getItem("getCompany") == "Axia" ? compData.map(data => <option value={data.CompanyName}>Sort By {data.CompanyName}</option>) : null}

        </select>

    <table className="ContentList">
    <thead>
        <tr>
        <th>Employee First Name</th>
        <th>Employee Last Name</th>
        <th>Department</th>
        <th>Situated Location</th>
        <th>Division</th>
        {sessionStorage.getItem("getCompany") == "Axia" ? <th>Company</th> : null}
        <th>Current Status</th>
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Added By</th> : null}    
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ?<th>Time Added</th> : null}
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ?<th>Last Update</th> : null}
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Updated By</th> : null}    
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Delete Employee</th> : null}
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Update Employee</th> : null}    
        {sessionStorage.getItem("getFlag") <= 2 ? <th>Device History</th> : null}  
        </tr>
        </thead>
    <tbody>


    {data.map(list => (
    <tr key={list.EmpID}>
    <td>{list.EmpFirstName}</td>
    <td>{list.EmpLastName}</td>
    <td>{list.EmpDepartment}</td>
    <td>{list.EmpLocation}</td>
    <td>{list.EmpDivision}</td>
    {sessionStorage.getItem("getCompany") == "Axia" ? <td>{list.EmpCompany}</td> : null}
    <td>{list.EmpStatus == "Contract" ? "Contractor" : list.EmpStatus == "Permenant" ? "Office Worker" : list.EmpStatus == "Remote" ? "Remote Worker" : list.EmpStatus}</td>
    {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td>{list.AddedBy}</td> : null}
    {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td>{list.TimeAdded}</td> : null}
    {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td>
    {list.lastUpdate ? list.lastUpdate.split("T")[0] : "-" }</td> : null}
    {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td>{list.UpdatedBy ? list.UpdatedBy.split("@")[0] : null}</td> : null}
    {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td><button value={list.EmpID} className="tableButton" onClick={e => handleDelete(e)} title="Delete Employee"><img src={deleteIcon}></img></button></td> : null}
    {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td><button value={list.EmpID} className="tableButton" onClick={e => updateEmployee(e)} title="Update Employee"><img src={updateIcon}></img></button></td> : null}
    {sessionStorage.getItem("getFlag") <= 2 ? <td><button value={list.EmpFirstName + " " + list.EmpLastName} className="tableButton" onClick={e => historyEmployee(e)} title="Device History For Employee"><img src={historyIcon}></img></button></td> : null}
    </tr>
    ))}
    </tbody>
    {pageButtons.map(data => (<button value={data} onClick={e => pageTing(e)} className="pageButtons">{data}</button>))}
    </table>
    </div>
    )
}

export default GetEmployees;