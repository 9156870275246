import { useState, useEffect } from "react"
import axios from "axios";
import config from "../Settings.json";
import { publicIpv4 } from "public-ip";
let userCheck = sessionStorage.getItem("getFlag");
function showPassword(){
   let pass = document.getElementById("showPass");

   if(pass.type === "password"){
      pass.type = "text";
   }
   else{
      pass.type = "password";
   }
}

const AddUser = () => {
    const[firstName ,setFName] = useState("");
    const[lastName ,setLName] = useState("");
    const[userName ,setUname] = useState("");
    const[password ,setPassword] = useState("");
    const[userStatus ,setStatus] = useState("");
    const[compData, setCompData] = useState([]);
    const[userEmail, setUemail] = useState("");
    const[userRole, setURole] = useState("");
    const[comp, setComp] = useState("");
    const[Error, setError] = useState("");
    const[ipv4Add, setIP] = useState("");
    const[changesArr] = useState({});
    
    useEffect(()=>{
        axios.put(config.DEV_URL + "/getCompanies").then(res =>{
            setCompData(res.data);
        }).catch(err=>{
            console.log(err);
        })
        getIP();
  }, []);

    async function getIP(){
      await publicIpv4().then(res => {
         setIP(res);
      })
    }

    const getFName = (e) => {
       setFName(e.target.value);
       changesArr["First-Name"] = e.target.value;
       e.preventDefault(); 
    }

    const getLName = (e) => {
        setLName(e.target.value);
        changesArr["Last-Name"] = e.target.value;
        e.preventDefault(); 
     }

     const getPass = (e) => {
        setPassword(e.target.value);
        e.preventDefault(); 
     }

     const getEmail = (e) => {
        setUemail(e.target.value);
        changesArr["Email"] = e.target.value;
        getUsername(e);
        e.preventDefault(); 
     }

     const getStatus = (e) => {
        setStatus(e.target.value);
        changesArr["Status"] = e.target.value;
        e.preventDefault(); 
     }
     const getUsername = (e) => {
      setUname(e.target.value);
      changesArr["Username"] = e.target.value;
      e.preventDefault();
     }
     const getComp = (e) =>{
        setComp(e.target.value);
        changesArr["Company"] = e.target.value;
        e.preventDefault();
     }

     const getRole = (e) =>{
      setURole(e.target.value);
      changesArr["Role"] = e.target.value;
      e.preventDefault();
     }

     const trailSubmit = () => {
      axios.put(config.DEV_URL + "/trailingChanges", {
         userName: sessionStorage.getItem("user?name"),
         actionTaken: "Adding LMS User",
         actionLoc: "Users Page",
         changeMade: JSON.stringify(changesArr),
         IPv4Addy: ipv4Add
      }).catch(err => {
         console.log(err);
      })
     }

     const handleSubmission = (e) => {
      axios.post(config.DEV_URL +"/checkUsername", {
            uName: userName
      }).then(res => {
         console.log(res.data);
         if(res.data == "No Records Found"){
            axios.post(config.DEV_URL +"/AddUsers", {
               fName: firstName,
               lName: lastName,
               Company: comp,
               uName: userName,
               Pass: password,
               flag: userStatus,
               Email: userEmail,
               role: userRole,
               addedBy: sessionStorage.getItem("user?name")
           }).then(res => {
            if(res != null){
               trailSubmit();
               window.location = "/getUsers";
            }
            else{
               window.alert("Entry Was not added, Re enter fields and try again");
            }
           }).catch(err => {
            console.log(err);
           })
         }
         else{
            setError("Cannot use this username");
         }
      }).catch(err => {
         console.log(err);
      })
        e.preventDefault();
     }


    return(
        <div>
         <div className="MainForms">
         <label className="formTitles">Add LMS User</label>
         <form onSubmit={e => handleSubmission(e)}>

            {/*<br></br>
            <label>Username</label>
            <br></br>
            <div className="formEffects">
            <input type="text" onChange={e=>getUsername(e)}></input>
            </div>*/}
            <br></br>
            <label>Email*</label>
            <br></br>
            <div className="formEffects">
            <input type="text" required onChange={e=>getEmail(e)}></input>
            </div>

            <br></br>
            <label>Company Position*</label>
            <br></br>
            <div className="formEffects">
            <input type="text" required onChange={e=>getRole(e)}></input>
            </div>

            <br></br>
            <label>Firstname*</label>
            <br></br>
            <div className="formEffects">
            <input type="text" required onChange={e=>getFName(e)}></input>
            </div>
            <br></br>

            <label>Lastname</label>
            <br></br>
            <div className="formEffects">
            <input type="text" onChange={e=>getLName(e)}></input>
            </div>
            <br></br>

            <label>Password*</label>
            <br></br>
            <div className="formEffects">
            <input type="password" id="showPass" required onChange={e=>getPass(e)} minLength={8}></input>
            <span>Show Password<input type="checkbox" onClick={showPassword}></input></span>
            </div>
            <br></br>

            <label>Company*</label>
            <br></br>
            <div className="formEffects">
            <select required onChange={e => getComp(e)}>
            <option disabled selected>Please Select an Option</option>
            <option value="Axia"> Axia</option>
            {compData != null ? compData.map(item => <option value={item.CompanyName}>{item.CompanyName}</option>) : null}
            </select>
            </div>

            <br></br>
            <label>Choose Status *</label>
            <br></br>
            <div className="formEffects">
            <select required onChange={e => getStatus(e)}>
                <option value="" selected disabled> Please Choose An Option</option>
                {userCheck == 0 ? <option value={0}>Axia Manager</option> : null}
                <option value={1}>Administrator</option>
                <option value={2}>Staff</option>
                <option value={3}>Offline</option>
            </select>
            <br></br>
            <p>{Error}</p>
            <br></br>
            </div>
      
            
            <button type="submit">
               Submit
            </button>
         </form>
         </div>
        </div>
    )
}

export default AddUser;