import axios from "axios";
import { useEffect, useState } from "react";
import config from "../Settings.json";
import { publicIpv4 } from "public-ip";

const GetUsers = () => {
const[data, setData] = useState([]);
const[compData, setCompData] = useState([]);
const[ipv4Add, setIP] = useState("");
let usernameCheck = sessionStorage.getItem("user?name");
useEffect(() =>{
    axios.post(config.DEV_URL + "/getUsersByComp", {
        comp: sessionStorage.getItem("getCompany")
    }).then(res =>{
        setData(res.data);
        //console.log(res.data);
    }).catch(err =>{
        console.log(err);
    });
    getIP();
    axios.put(config.DEV_URL + "/getCompanies").then(res =>{
        setCompData(res.data);
    }).catch(err=>{
        console.log(err);
    })
    
}, []);

async function getIP(){
    await publicIpv4().then(res => {
        setIP(res);
    })
}

const getSearchVal = (e) => {
    let onClickFunc = document.getElementById("searchVal");
    if(onClickFunc.addEventListener("keypress", function(c){
        if(c.key === "Enter"){
            searchList(e);
            c.stopImmediatePropagation();
        }
    }))
    e.preventDefault();
}
const delUserDetails = async (e) => {
    let data = {};
    await axios.post(config.DEV_URL + "/getUserDetailsID", {
        uID: e,
        uComp: sessionStorage.getItem("getCompany")
    }).then(res => {
        data = res.data;
    }).catch(err => {
        console.log(err);
    })

    return data;
}
const handleDelete = async (e) =>{
    if(window.confirm("Do you want to delete User?") === true){
       let deletedResult = {};
       await delUserDetails(e.target.value).then(delRes => {
        deletedResult = delRes;
       }).catch(err => {
        console.log(err);
       });

    axios.put(config.DEV_URL + "/trailingChanges", {
        userName: sessionStorage.getItem("user?name"),
        actionTaken: "Deleting LMS User",
        actionLoc: "Users Page",
        changeMade: JSON.stringify(deletedResult),
        IPv4Addy: ipv4Add
     }).catch(err => {
        console.log(err);
     })

    axios.put(config.DEV_URL +"/DeleteUsers", {
        uID: e.target.value
    }).then(res => {
        if(res == null){
            console.log("Invalid ID");
        }
        else{
            window.location.reload(); 
            //console.log(res);
        }
    }).catch(err => {
        console.log(err);
    })
   }
}

const addUser = () => {
    window.location = "/add-user";
}

const filterData = (e) =>{
    axios.post(config.DEV_URL + "/getUsersByComp", {
        comp: e.target.value
    }).then(res =>{
        setData(res.data);
        //console.log(res.data);
    }).catch(err =>{
        console.log(err);
    });
  }

  const updateUser = (e) => {
        window.location = `/Update-Users/${e.target.value}`;
}


 const searchList = (e) => {
    axios.post(config.DEV_URL + "/getUserSearch", {
        Filt: e.target.value
    }).then(res =>{
        setData(res.data);
        //console.log(res.data);
    }).catch(err =>{
        console.log(err);
    });
}

return(
    <div>
        <h2 className="pageTitle">LMS Users</h2>
        <div className="buttontoAdd">
        <button onClick={() => addUser()}>Add A User</button>
        <span> 🔍 </span>
        <input type="search" id="searchVal" onChange={e => getSearchVal(e)}>
        </input>

        </div>

        <select className="filterList" onClick={e => filterData(e)}>
            <option value={"Axia"} selected>Sort By Axia</option>
            {compData.map(item => 
                (<option value={item.CompanyName}>Sort By {item.CompanyName}</option>))}
            <option value={"ASC"}>Sort By Oldest</option>
            <option value={"DESC"}>Sort By Newest</option>
        </select>

    <table className="ContentList">
    <thead>
        <tr>
        <th>Role</th>
        <th>Username</th>
        <th>User First Name</th>
        <th>User Last Name</th>
        <th>User Company</th>
        <th>User Email</th>
        <th>User Status</th>
        <th>Time Added</th>
        <th>Last Update</th>
        <th>Delete User</th>
        <th>Update User</th>
        </tr>
        </thead>
    <tbody>


    {data.map(list => (
    <tr key={list.UserID}>
    <td>{list.JobRole}</td>
    <td>{list.Username}</td>
    <td>{list.Firstname}</td>
    <td>{list.Lastname}</td>
    <td>{list.Company}</td>
    <td>{<a href={`mailto:${list.Email}`}>{list.Email}</a>}</td>
    <td>{list.UserFlag == 0 ? "Axia Admin" : list.UserFlag == 1 ? "Administrator" : list.UserFlag == 2 ? "Staff" : "Offline"}</td>
    <td>{list.UserAdded.split("T")[0]}</td><td>
    {list.lastUpdate != null ? list.lastUpdate.split("T")[0] : null}</td>
    {list.Username == usernameCheck ?<td>Logged In</td> : list.UserFlag == 0 ? <td>Admin</td> : <td><button value={list.UserID} className="tableButton" onClick={e => handleDelete(e)}>Delete</button></td>}
    {list.Username == usernameCheck ?<td>Go to settings</td> : list.UserFlag == 0 ? <td>Admin</td> : <td><button value={list.UserID} className="tableButton" onClick={e => updateUser(e)}>Update</button></td>}
    </tr>
    ))}
    </tbody>
    </table>
    </div>
    )
}

export default GetUsers;