import axios from "axios";
import { useEffect, useState } from "react";
import config from "../Settings.json";

const GetAssetsReport = () => {
const[data, setData] = useState([]);
const[latestTime, setTime] = useState("");

useEffect(() =>{
    
    axios.post(config.DEV_URL + "/getAllMainDetailsLimit", {
        comp :sessionStorage.getItem("getCompany")
    }).then(res =>{
        setData(res.data);
        //console.log(res.data);
    }).catch(err =>{
        console.log(err);
    });
  getTime();
}, []);

function getTime(){
    setTime(new Date());
}

setTimeout(getTime, 1000);

const getSearchVal = (e) => {
    searchList(e);
    /*let onClickFunc = document.getElementById("searchVal");
    if(onClickFunc.addEventListener("keypress", function(c){
        if(c.key === "Enter"){
            searchList(e);
            c.stopImmediatePropagation();
        }
    }))*/
    e.preventDefault();
}

 const searchList = (e) => {
    axios.post(config.DEV_URL + "/getAssetSearchLimit", {
        assetComp: sessionStorage.getItem("getCompany"),
        Filt: e.target.value
    }).then(res =>{
        setData(res.data);
    }).catch(err =>{
        console.log(err);
    });
}

return(
    <div>
            <h2 className="pageTitle">Assets</h2>
    <div className="buttontoAdd"><button onClick={window.print}>Print</button>
    <span> 🔍 </span>
        <input type="search" id="searchVal" onChange={e => getSearchVal(e)}>
        </input>
        </div>
    <div className="reportTime">
        <h3>Printed: {latestTime.toString().split("GMT")[0]}</h3>
    </div>
    <table className="ContentList">
    <thead>
        <tr>
        <th>Owner Name</th>
        <th>Division</th>
        <th>Department</th>
        <th>Location</th>
        <th>Device Name</th>
        <th>Device Status</th>
        <th>Serial Number</th>
        {sessionStorage.getItem("getCompany") == "Axia" ? <th>Company</th> : null}
        <th>Type Of Asset</th>
        <th>Is Asset In Use</th>
        <th>Date Asset Given</th>
        <th>Asset Expiry Date</th>
        <th>Added By</th>
        </tr>
        </thead>
    <tbody>

    {data ? data.map(list => (
    <tr key={list.AssetID ? list.AssetID : 1}>
    <td>{list.OwnerOfAsset ? list.OwnerOfAsset : null}</td>
    <td>{list.OwnerDivision ? list.OwnerDivision : null}</td>
    <td>{list.OwnerDepartment ? list.OwnerDepartment : null}</td>
    <td>{list.OwnerLocation ? list.OwnerLocation: null}</td>
    <td>{list.AssetName ? list.AssetName : null}</td>
    <td>{list.DeviceStatus ? list.DeviceStatus : null}</td>
    <td>{list.SerialNumber ? list.SerialNumber : null}</td>
    {sessionStorage.getItem("getCompany") == "Axia" && list.AssetComp != null ? <td>{list.AssetComp}</td> : null}
    <td>{list.AssetType ? list.AssetType : null}</td>
    <td>{list.AssetInUse == "true" ? "Yes" : list.AssetInUse == "false" ? "No" : null}</td>
    <td>{list.AssetTime ? (list.AssetTime.replace("T", " ").split(".0")[0]) : null}</td>
    <td>{list.AssetExpiry ? (list.AssetExpiry.split(" ")[0]) : "No Expiry Date Given"}</td>
    <td>{list.AddedBy}</td>
    </tr>
    )) : null}
    </tbody>
    </table>
    </div>
    )
}

export default GetAssetsReport;