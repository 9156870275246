import axios from "axios";
import { useState } from "react";
import config from "../Settings.json";
const image = require("../../Images/CompanyLogos/Axia.png");
const ForgotPass = () => {
    const [Username, setUname] = useState(null);
    const [email, setEmail] = useState(null);
    const [Error, setError] = useState("Enter Details, if successful you will receive the password in your mailbox that is associated with the account");

    const getUname = (e) =>{
        setUname(e.target.value);
        e.preventDefault();
    }

    const getUemail = (e) =>{
        setEmail(e.target.value);
        e.preventDefault();
    }
    const submission = async (e) => {
        await axios.post(config.DEV_URL +"/forgotPass", {
            uName:Username,
            uEmail:email
        }).then(res => {
            if(res.data.length != 0 && res.data != null){
                axios.post(config.DEV_URL +"/ForgotPassEmail", {
                 uName: Username,
                 uEmail: email,
                 uPass: res.data.pass,
                 fName: res.data.passResult.map(data => data.Firstname),
                 lName: res.data.passResult.map(data => data.Lastname)     
                }).catch(err=>{
                    setError("An Error Occurred, Please contact Administrator"); 
                    //console.log(err);
                })
                setError("If the email and username is Correct, you will have been sent an Email");
            }
            else{
                setError("Email is not registered.");
            }
           }).catch(err => {
            setError("An Error Occurred, Please contact Administrator");     
            console.log(err);
           })
           e.preventDefault();
  }
  const fUser = (e) => {
    window.location = "/forgot-User";
    e.preventDefault();
}

    return(
        <div>
            {<div className="loginBackground"></div>}
            {<div className="loginBackground2"></div>}
            <div className="MainLogo"><img src={image}></img></div>
            <div className="loginSection">

                <div className="forgotDesign">
                    <form>
        <label>Enter Username</label>
        <br></br>
        <input type="text" onChange={e => getUname(e)}></input>
        <br></br>
        <label>Enter Email Associated with Username</label>
        <br></br>
        <input type="text" onChange={e=>getUemail(e)}></input>
        <br></br>
        <br></br>
        <button type="submit" onClick={e => submission(e)}>Submit</button>
        <br></br>
        <button onClick={e => fUser(e)}>Forgot Username</button>
        <br></br>
        {<p>{Error}</p>}
        </form>
              </div>
            </div>
        </div>
    );
}

export default ForgotPass;