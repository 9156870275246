import { useState, useEffect } from "react"
import axios from "axios";
import config from "../Settings.json";
import { publicIpv4 } from "public-ip";
const AddEmployee = () => {

    const[fName ,setFName] = useState("");
    const[lName ,setLName] = useState("");
    const[comp, setComp] = useState(sessionStorage.getItem("getCompany"));
    const[department ,setDepartment] = useState("-");
    const[loc ,setLoc] = useState("-");
    const[empDivision,setEmpDiv] = useState("-");
    const[Status ,setStatus] = useState("-");
    const[data, setData] = useState([]);
    const[date, setDate] = useState(null);
    const[cData, setCData] = useState([]);
    const[depData, setDepData] = useState([]);
    const[HQData, setHQData] = useState([]);
    const[DivData, setDivData] = useState([]);
    const[ipv4Add, setIP] = useState("");
    const [changesArr] = useState({});
    let headQArr = [];
    let DepdataArr = [];
    let DivisionArr = [];

    useEffect(()=>{
      axios.put(config.DEV_URL + "/getInfo", {
          compName:comp
      }).then(res => {
         if(res.data != "results not found")
         {
         setData(res.data);
         setDivData(new Set(res.data.map(item => item.CompanyDivision)));
         setDepData(new Set(res.data.map(item => item.CompanyDepartment)));
         setHQData(new Set(res.data.map(item => item.Headquarters)));
         }
         else
         {
             setDivData(null);
             setDepData(null);
             setHQData(null);
         }
      }).catch(err => {
          console.log(err);
      })      

      axios.put(config.DEV_URL + "/getCompanies").then(res =>{
         setCData(res.data);
     }).catch(err =>{
         console.log(err);
     });
  }, [depData, HQData, DivData]);

  useEffect(()=>{
   getIP();
  },[])

   async function getIP(){
      await publicIpv4().then(res => {
         console.log(res);
         setIP(res);
      })
   }

    const getFName = (e) => {
       setFName(e.target.value);
       changesArr["First-Name"] = e.target.value;
       e.preventDefault(); 
    }

    const getLName = (e) => {
        setLName(e.target.value);
        changesArr["Last-Name"] = e.target.value;
        e.preventDefault(); 
     }

     const getDepart = (e) => {
        setDepartment(e.target.value);
        changesArr["Department"] = e.target.value;
        e.preventDefault(); 
     }

     const getCDiv = (e) => {
        setEmpDiv(e.target.value);
        changesArr["Division"] = e.target.value;
        e.preventDefault(); 
     }

     const getComp = (e) => {
      if(sessionStorage.getItem("getCompany") == "Axia"){
         setComp(e.target.value);
         changesArr["Company"] = e.target.value;
         getCompDepHQ(e);
      }
      else{
         setComp(sessionStorage.getItem("getCompany"));
         changesArr["Company"] = sessionStorage.getItem("getCompany");
      }
      e.preventDefault(); 
   }

   const getCompDepHQ = (e) => {
      axios.put(config.DEV_URL + "/getInfo", {
         compName:e.target.value
     }).then(res => {
      if(res.data != "results not found")
      {
      setDivData(new Set(res.data.map(item => item.CompanyDivision)));
      setDepData(new Set(res.data.map(item => item.CompanyDepartment)));
      setHQData(new Set(res.data.map(item => item.Headquarters)));
      }
      else
      {
         setDivData(null);
         setDepData(null);
         setHQData(null);
      }
     }).catch(err => {
         console.log(err);
     })
   }

     const getLocation = (e) => {
        setLoc(e.target.value);
        changesArr["Location"] = e.target.value;
        e.preventDefault(); 
     }

     const getStatus = (e) => {
        setStatus(e.target.value);
        changesArr["Status"] = e.target.value;
        e.preventDefault(); 
     }
     const getDate = (e) => {
      setDate(e.target.value);
      changesArr["Date Added"] = e.target.value;
      e.preventDefault();
     }

     if(HQData != null){
      HQData.forEach((i , key) => {
         if(i != "" && i != " " && i != null){
      headQArr.push(<option key={key} value={i}>{i}</option>);
      }
      })
   }
      else{
      <option></option>
   }
   if(depData != null){
      depData.forEach((i , key) => {
         if(i != "" && i != " " && i != null){
            DepdataArr.push(<option key={key} value={i}>{i}</option>);
         }
      })
   }
   else{
      <option></option>
   }
   if(DivData != null){
      DivData.forEach((i , key) => {
         if(i != "" && i != " " && i != null){
         DivisionArr.push(<option key={key} value={i}>{i}</option>);
         }
      })
   }
   else{
      <option></option>
   }


     const handleSubmission = (e) => {
      axios.put(config.DEV_URL + "/trailingChanges", {
         userName: sessionStorage.getItem("user?name"),
         actionTaken: "Adding Employee",
         actionLoc: "Adding Employees Page",
         changeMade: JSON.stringify(changesArr),
         IPv4Addy: ipv4Add
      }).catch(err => {
         console.log(err);
      })
        axios.post(config.DEV_URL +"/addEmployees", {
            fName: fName,
            lName: lName,
            Company: comp,
            Location: loc,
            Department: department,
            empStatus: Status,
            joinedDate: date,
            empDiv: empDivision,
            empAddedBy: sessionStorage.getItem("user?name")
        }).then(res => {
         if(res != null){
            //console.log(res);
            window.location = "/EmployeeList";
         }
         else{
            window.alert("Entry Was not added, Re enter fields and try again");
         }
        }).catch(err => {
         console.log(err);
        })
        e.preventDefault();
     }
     const showFrame = () => {
      document.getElementById("depFrame").style.height = "450px";
      document.getElementById("depFrame").style.border = "1px solid black";
     }

    return(
        <div>
         <div className="MainForms">
         <form onSubmit={e => handleSubmission(e)}>
            <label>Enter Firstname*</label>
            <br></br>
            <div className="formEffects">
            <input type="text" required onChange={e=>getFName(e)} placeholder="Enter First Name"></input>
            </div>
            <br></br>
            <label>Enter Lastname*</label>
            <br></br>
            <div className="formEffects">
            <input type="text" required onChange={e=>getLName(e)} placeholder="Enter Last Name"></input>
            </div>

            {sessionStorage.getItem("getCompany") == "Axia" ?
            <div>
            <label>Company Name*</label>
            <br></br>
            <div className="formEffects">
            <select required onChange={e => getComp(e)}>
            <option value={"Axia"} selected>Axia</option>
            {(cData !== "results not found" ? cData.map(item => 
            (item.CompanyName != "" ? <option value={item.CompanyName}>
            {item.CompanyName}</option> : null)) : null)}
            </select>
            </div> </div>: null
            }
            <label>Department</label>
            <br></br>
            <div className="formEffects">
            <select onChange={e => getDepart(e)}>
            <option disabled selected>Please Select an Option</option>
            {DepdataArr != null ? DepdataArr : null}
            </select>
            </div>
            <br></br> 
            <button type="button" onClick={() => showFrame()}>Add Dep/Loc Not In List</button>
            <div className="formEffects">
            <label>Location</label>
            <br></br>
            <select onChange={e => getLocation(e)}>
            <option disabled selected>Please Select an Option</option>
            {headQArr != null ? headQArr : null}
            </select>
            </div>

            <br></br>

            <div className="formEffects">
            <label>Division</label>
            <br></br>
            <select onChange={e => getCDiv(e)}>
            <option disabled selected>Please Select an Option</option>
            {DivisionArr != null ? DivisionArr: null}
            </select>
            </div>

            <br></br>
            <label>Status</label>
            <br></br>
            <div className="formEffects">
            <select onChange={e=>getStatus(e)}>
               <option value="" disabled selected>Please Select an Option</option>
               <option value="Contract">Contractor</option>
               <option value="Permenant">Office Worker</option>
               <option value="Remote">Remote Worker</option>
               <option value="Not Working">Left</option>
            </select>
            </div>

            <br></br>
            <label>Employee Join Date</label>
            <br></br>
            <div className="formEffects">
            <input type="date" onChange={e=>getDate(e)}></input>
            </div>
            
            <button type="submit">
               Submit
            </button>
         </form>
         <iframe className="depIframe" id="depFrame" src="/add-compInfo"></iframe>
         </div>
        </div>
    )
}


export default AddEmployee;