import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";
import { publicIpv4 } from "public-ip";
const config = require( "../Settings.json");

const UpdateDevice = () =>{
    const ID = useParams();
    const [data, setData] = useState([]);
    const [cond, setGrade] = useState("");
    const [error, setError] = useState("");
    const [Stat, setStat] = useState("");
    const [notes, getNote] = useState("No Notes");
    const [ManFact, setManu] = useState("");
    const [DevName, setDevName] = useState("");
    const [DevUID, setDevUID] = useState(null);
    const [serialImg, setSerialImg] = useState(null);
    const [conditionImg, setCondImg] = useState(null);
    const [serialNumber, setSN] = useState("");
    const [useImg, setUseImg] = useState(null);
    const [compData, setComp] = useState("");
    const [tick, setTick] = useState("#");
    const [optiVid, setVid] = useState(null);
    const [isWipe, setWipe] = useState(false);
    const [changesArr] = useState({});
    const [ipv4Addy, setIP] = useState("");
    const formdata = new FormData();

    async function getIP(){
        await publicIpv4().then(res => {
            setIP(res);
        })
    }

    useEffect(()=>{
        getIP();

        axios.post(config.DEV_URL + "/GetDevicesByID", {
            devID: ID.dID,
            comp:sessionStorage.getItem("getCompany")
        }).then(res => {
            setData(res.data);
            setStat(res.data.map(list => list.StatusOfDevice));
            setGrade(res.data.map(list => list.ConditionOfDevice));
            getNote(res.data.map(list => list.AdditionalNotes));
            setManu(res.data.map(list => list.Manufacturer));
            setDevName(res.data.map(list => list.DeviceName));
            setDevUID(res.data.map(list => list.DeviceUniqueID));
            setSerialImg(res.data.map(list => list.ImageFileSerialNum));
            setCondImg(res.data.map(list => list.ImageFileCondition));
            setUseImg(res.data.map(list => list.ImageFileInUse));
            setSN(res.data.map(list => list.SerialNumber));
            setComp(res.data.map(list => list.CompanyDevice));
            setTick(res.data.map(list => list.ticketNumber));
            setWipe(res.data.map(list => list.isWiped));
            setVid(res.data.map(list => list.videoOfDevice));

            if(res.data.map(list => list.isWiped) == 1){
                document.getElementById("wipe").checked = true;
            }
            else{
                document.getElementById("wipe").checked = false;
            }
        })
    }, [])

    

    const getManu = (e) => {
        if(e.target.value == ""){
            delete changesArr["Prev-Manufacturer"];
            delete changesArr["New-Manufacturer"];
            setManu(data.map(list => list.Manufacturer));
        }
        else{
        changesArr["Prev-Manufacturer"] = data.map(list => list.Manufacturer);
        changesArr["New-Manufacturer"] = e.target.value;
        setManu(e.target.value);
        }
        e.preventDefault();
    }
    const getDevName = (e) => {
        if(e.target.value == ""){
            setDevName(data.map(list => list.DeviceName));
            delete changesArr["Prev-DeviceName"];
            delete changesArr["New-DeviceName"];
        }
        else{
            changesArr["Prev-DeviceName"] = data.map(list => list.DeviceName);
            changesArr["New-DeviceName"] = e.target.value;
            setDevName(e.target.value);
        }
        e.preventDefault();
    }
    const getDevUID = (e) => {
        if(e.target.value == ""){
            setDevUID(data.map(list => list.DeviceUniqueID));
            delete changesArr["Prev-DeviceUniqueID"];
            delete changesArr["New-DeviceUniqueID"];
        }
        else{
            changesArr["Prev-DeviceUniqueID"] = data.map(list => list.DeviceUniqueID);
            changesArr["New-DeviceUniqueID"] = e.target.value;
        setDevUID(e.target.value);
        }
        e.preventDefault();
    }

    const getStatusDev = (e) => {
        if(e.target.value == ""){
            setStat(data.map(list => list.StatusOfDevice));
            delete changesArr["New-StatusOfDevice"];
            delete changesArr["Prev-StatusOfDevice"]
        }
        else{
            changesArr["Prev-StatusOfDevice"] = data.map(list => list.StatusOfDevice);
            changesArr["New-StatusOfDevice"] = e.target.value;
            setStat(e.target.value);
        }
        e.preventDefault();
    }

    const getCondition = (e) => {

        if(e.target.value == ""){
            setGrade(data.map(list => list.ConditionOfDevice));
            delete changesArr["Prev-ConditionOfDevice"];
            delete changesArr["New-ConditionOfDevice"]
        }
        else{
            changesArr["Prev-ConditionOfDevice"] = data.map(list => list.ConditionOfDevice);
            changesArr["New-ConditionOfDevice"] = e.target.value;
        setGrade(e.target.value);
        }
        e.preventDefault();
    }

    const getTicket = (e) => {
        changesArr["Prev-Ticket No"] = data.map(list => list.ticketNumber);
        changesArr["New-Ticket No"] = e.target.value;
        setTick(e.target.value);
        e.preventDefault();
    }

    const getWiped = () => {
        changesArr["Prev-isWiped"] = data.map(list => list.isWiped);
        if(document.getElementById("wipe").checked == true){
            setWipe(true);
            changesArr["New-isWiped"] = true;
        }
        else{
            changesArr["New-isWiped"] = false;
            setWipe(false);
        }
        
    }

    const getSerialImg = (e) => {

        if(e.target.files.length == 1 && e.target.files[0].size > (2048 * 2048)){
            window.alert("Only upload 2mb max");
            setSerialImg(data.map(list => list.ImageFileSerialNum));
            delete changesArr["Prev-ImageFileSerialNum"];
            delete changesArr["New-ImageFileSerialNum"];
            return false;
        }
        else if(e.target.files.length == 0){
            setSerialImg(data.map(list => list.ImageFileSerialNum));
            delete changesArr["Prev-ImageFileSerialNum"];
            delete changesArr["New-ImageFileSerialNum"];
        }
        else{
            changesArr["Prev-ImageFileSerialNum"] = data.map(list => list.ImageFileSerialNum);
            changesArr["New-ImageFileSerialNum"] = e.target.files[0].name;
            setSerialImg(e.target.files);
        }
        e.preventDefault();
    }
    const getConditionImg = (e) => {

        if(e.target.files.length == 1 && e.target.files[0].size > (2048 * 2048)){
            window.alert("Only upload 2mb max");
            setCondImg(data.map(list => list.ImageFileCondition));
            delete changesArr["Prev-ImageFileCondition"];
            delete changesArr["New-ImageFileCondition"];
            return false;
        }
        else if(e.target.files.length == 0){
            delete changesArr["Prev-ImageFileCondition"];
            delete changesArr["New-ImageFileCondition"];
            setCondImg(data.map(list => list.ImageFileCondition));
        }
        else{
            changesArr["Prev-ImageFileCondition"] = data.map(list => list.ImageFileCondition);
            changesArr["New-ImageFileCondition"] = e.target.files[0].name;
            setCondImg(e.target.files);
        }
        e.preventDefault();
    }
    const getUseImg = (e) => {

        if(e.target.files.length == 1 && e.target.files[0].size > (2048 * 2048)){
            window.alert("Only upload 2mb max");
            delete changesArr["Prev-ImageFileInUse"];
            delete changesArr["New-ImageFileInUse"];
            e.preventDefault();
        }
        else if(e.target.files.length == 0){
            delete changesArr["Prev-ImageFileInUse"];
            delete changesArr["New-ImageFileInUse"];
            setUseImg(data.map(list => list.ImageFileInUse));
        }
        else{
            changesArr["Prev-ImageFileInUse"] = data.map(list => list.ImageFileInUse);
            changesArr["New-ImageFileInUse"] = e.target.files[0].name;
            setUseImg(e.target.files);
        }
        e.preventDefault();
    }

    const getVideo = (e) => {
        if(e.target.files.length == 1 && e.target.files[0].size > (10240 * 10240)){
            window.alert("Only upload 10mb max");
            delete changesArr["Prev-Video"];
            delete changesArr["New-Video"];
            e.preventDefault();
        }
        else{
            changesArr["Prev-Video"] = data.map(list => list.videoOfDevice);
            changesArr["New-Video"] = e.target.files[0].name;
            setVid(e.target.files);
        }
        e.preventDefault();
    }

    const setNotes = (e) => {
        if(e.target.value == ""){
            getNote(data.map(list => list.AdditionalNotes));
            delete changesArr["Prev-Notes"];
            delete changesArr["New-Notes"];
        }
        else{
            changesArr["Prev-Notes"] = data.map(list => list.AdditionalNotes);
            changesArr["New-Notes"] = e.target.value;
            getNote(e.target.value);
        }
        
        e.preventDefault();
    }

    const handleSubmission = (e) => {

          if(serialImg != null){
            formdata.append("files[]", serialImg[0]);
          }
          if(conditionImg != null){
            formdata.append("files[]", conditionImg[0]);
          }
          if(useImg != null || useImg != ""){
            formdata.append("files[]", useImg[0]);
          }
          if(optiVid != null || optiVid != ""){
            formdata.append("files[]", optiVid[0]);
          }
          formdata.append("serialNum", serialNumber);
          formdata.append("comp", compData);                    

    if(notes.length <= 5000){
        axios.post(config.DEV_URL + "/UpdateDevice", {
            comp: compData,
            devID: ID.dID,
            devStatus: Stat,
            devCond: cond,
            additionalNotes: notes,
            devUpdatedBy: sessionStorage.getItem("user?name"),
            serialImage: serialImg[0].name != null ? serialImg[0].name : serialImg,
            conditionImage: conditionImg[0].name != null ? conditionImg[0].name : conditionImg,
            useImage: useImg != "" ? (useImg[0].name != null ? useImg[0].name : useImg) : null ,
            devVideo: optiVid != "" ? (optiVid[0].name != null ? optiVid[0].name : optiVid) : null,
            manufacturer: ManFact,
            devName: DevName,
            ticket: tick,
            isWiped: isWipe,
            uniqueID: DevUID,

        }).then(result => {
            console.log(result);
            
            if(result){
                axios.post(config.DEV_URL + "/Save-Image", formdata).then(res => {
                    //console.log(res);
                    window.location = "/CurrentHardware";
                }).catch(err => {
                    console.log(err);
                })
                axios.put(config.DEV_URL + "/trailingChanges", {
                    actionTaken: `Updating Device: ${data.map(i => i.DeviceName)}`,
                    actionLoc: "Device Update Page",
                    changeMade: JSON.stringify(changesArr),
                    IPv4Addy: ipv4Addy,
                    userName: sessionStorage.getItem("user?name")
                }).catch(err => {
                    console.log(err);
                })
            }
        }).catch(err => {
            console.log(err);
        })
     }
     else{
        setError("Limit to 1000 characters for notes max");
     }
    
        e.preventDefault();
    }

    return(
        <div>
        
        <div className="MainForms">
         <form encType="multipart/form-data" onSubmit={e => handleSubmission(e)}>
            <label>Manufacturer</label>
            <br></br>
            <div className="formEffects">
            <input type="text" placeholder={data.map(list => list.Manufacturer)} onChange={e=>getManu(e)}></input>
            </div>
            <br></br>

            {
            sessionStorage.getItem("getCompany") == "Axia" ?
            <div>
            <label>Ticket Number</label>
            <br></br>
            <div className="formEffects">
            <input type="text" placeholder={data.map(list => list.ticketNumber)} onChange={e=>getTicket(e)}></input>
            </div>
            <br></br></div> : null
            }

            <label>Device Name</label>
            <br></br>
            <div className="formEffects">
            <input type="text" placeholder={data.map(list => list.DeviceName)} onChange={e => getDevName(e)}></input>
            </div>
            <br></br>

            <label>Equipment ID</label>
            <br></br>
            <div className="formEffects">
            <input type="text" id="uniqueID" placeholder={data.map(list => list.DeviceUniqueID)} onChange={e => getDevUID(e)}></input>
            </div>
            <br></br>

            <div className="formEffects">
            <label>Serial Number</label>
            <br></br>
            <input type="text" disabled placeholder={data.map(list => list.SerialNumber)}></input>
            <br></br>
            </div>

            <br></br>
            <label>Condition Grade</label>
            <br></br>
            <div className="formEffects">
            <select
            title="Grade A: Excellent Condition 
            Grade B: Good Condition with minor wear and tear
            Grade C: Fair Condition with signs of usage
            Grade D: Broken, needs fixes, or purely in bad condition"
             onChange={e => getCondition(e)}>
                
                <option value="" disabled selected>{data.map(list => list.ConditionOfDevice)}</option>
                <option value="Grade A" title="Excellent Condition">Grade A (Excellent)</option>
                <option value="Grade B" title="Good Condition with some minor wear and tear">Grade B (Good)</option>
                <option value="Grade C" title="Fair Condition with signs of usage">Grade C (Fair)</option>
                <option value="Grade D" title="Broken, needs fixes, or purely in bad condition">Grade D (Broken/Needs Attention)</option>
            </select>
            </div>

            <br></br>
            <label>Status of Device</label>
            <br></br>
            <div className="formEffects">
            <select onChange={e => getStatusDev(e)}>
                <option value="" disabled selected>{data.map(list => list.StatusOfDevice)}</option>
                <option value="New">Brand New (Condition Must be Grade A)</option>
                <option value="Renewed">Renewed</option>
                <option value="Recycled">Recycled</option>
                <option value="Pool">Pool</option>
                <option value="Disposable">Disposed</option>
            </select>
            </div>

            <label>Wiped?</label>
            <input type="checkbox" id="wipe" onClick={getWiped}></input>
            <br></br>
            <br></br>

            <label>Device Image With Serial Number Upload</label>
            <br></br>
            <div className="formEffects">
                <input type="file" name="myFile" accept="image/png, image/jpeg" onChange={e => getSerialImg(e)}></input>
            </div>
            <br></br>

            <label>Condition of Device</label>
            <br></br>
            <div className="formEffects">
                <input type="file" name="myFile2" accept="image/png, image/jpeg" onChange={e => getConditionImg(e)}></input>
            </div>
            <br></br>

            <label>Device In Use/In Packaging if New</label>
            <br></br>
            <div className="formEffects">
                <input type="file" name="myFile3" accept="image/png, image/jpeg" onChange={e => getUseImg(e)}></input>
            </div>
            <br></br>

            <label>Optional Video</label>
            <br></br>
            <div className="formEffects">
                <input type="file" name="myFile4" accept="video/mp4" onChange={e => getVideo(e)}></input>
            </div>
            <br></br>

            <label>Notes</label>
            <div className="formEffects">
                <textarea onChange={e => setNotes(e)} maxLength={5000} defaultValue={data.map(list => list.AdditionalNotes)}></textarea>
            </div>
            {error}
            <br></br>

            <button type="button" onClick={e => handleSubmission(e)}>Submit</button>
            <br></br>

            </form>
         </div>
        </div>
    );
}

export default UpdateDevice;