import axios from "axios";
import { useState } from "react";
import config from "../Settings.json";
const image = require("../../Images/CompanyLogos/Axia.png");
const ForgotUser = () => {
    const [email, setEmail] = useState(null);
    const [Error, setError] = useState("Enter Details, if successful you will receive the username in your mailbox that is associated with the account");

    const getUemail = (e) =>{
        setEmail(e.target.value);
        e.preventDefault();
    }
    const submission = async (e) => {
        await axios.post(config.DEV_URL + "/forgotUser", {
            uEmail:email
        }).then(res => {
            if(res.data.length != 0 && res.data != null){
                axios.post(config.DEV_URL +"/ForgotUserEmail", {
                 uName: res.data.map(data => data.Username),
                 uEmail: email,
                 fName: res.data.map(data => data.Firstname),
                 lName: res.data.map(data => data.Lastname)   
                }).catch(err => {
                    setError("Error sending email"); 
                    //console.log(err);
                })
                setError("If the email is Correct, you will have been sent an Email");
            }
            else{
                setError("If the email is Correct, you will have been sent an Email");
            }
           }).catch(err2 => {
            setError("Error sending email"); 
            //console.log(err2);
           })
           e.preventDefault();
  }

    return(
        <div>
            {<div className="loginBackground"></div>}
            {<div className="loginBackground2"></div>}
            <div className="MainLogo"><img src={image}></img></div>
            <div className="loginSection">
                <div className="forgotDesign">
        <form>
        <label>Enter Email</label>
        <br></br>
        <input type="text" onChange={e=>getUemail(e)}></input>
        <br></br>
        <br></br>
        <button type="submit" onClick={e => submission(e)}>Submit</button>
        <br></br>
        <p>{Error}</p>
        </form>
                </div>
            </div>
        </div>
    );
}

export default ForgotUser;