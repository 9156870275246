import { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import config from "../Settings.json";
import { publicIpv4 } from "public-ip";
const UpdateAsset = () => {
    const eID = useParams();
    const[data, setData] = useState([]);
    const [inUse, getInUse] = useState("");
    const [date, getDate] = useState("");
    const [error, setError] = useState("");
    const [notes, getNote] = useState("No Notes");
    const [ipv4Addy, setIP] = useState("");
    const [changesArr] = useState({});

    async function getIP(){
        await publicIpv4().then(res => {
            setIP(res);
        })
    }
    useEffect(()=>{
        getIP();
        axios.post(config.DEV_URL + "/getAssetsByID", {
            Company: sessionStorage.getItem("getCompany"),
            assetID: eID.aID
        }).then(res => {
            if(res){
                setData(res.data);
                getInUse(res.data.map(list => list.AssetInUse));
                getDate(res.data.map(list => list.AssetExpiry));
                getNote(res.data.map(list => list.AssetNotes));
            }
            else{
                setData([]);
            }
        }).catch(err => {
            console.log(err);
        })
    }, [])


    const setInUse = (e) => {
        changesArr["Prev In-use"] = data.map(i => i.AssetInUse);
        changesArr["New In-use"] = e.target.value;
        getInUse(e.target.value);
        e.preventDefault();
    }

    const setDate = (e) => {
        changesArr["Prev Exp-Date"] = data.map(i => i.AssetExpiry);
        changesArr["New Exp-Date"] = e.target.value;
        getDate(e.target.value);
        e.preventDefault();
    }

     const setNotes = (e) => {
        changesArr["Prev Notes"] = data.map(i => i.AssetNotes);
        changesArr["New Notes"] = e.target.value;
        getNote(e.target.value);
        e.preventDefault();
    }


    const handleSubmission = (e) => {
        let giveDate = data.map(list => list.AssetGivenTime).toString();
        let newEditedDate = giveDate.split(" ")[0];
        
        if(date < newEditedDate && date != ""){
            setError("Error: Expiry Date and Asset Given Date MUST have a 24 Hour difference in Between")
        }
        else{
        axios.put(config.DEV_URL + "/updateAsset", {
            usage: inUse,
            expAsset: date,
            assetID: eID.aID,
            assetNotes: notes,
            assetUpdatedBy:sessionStorage.getItem("user?name"),
            Company: sessionStorage.getItem("getCompany")
        }).then(res => {
             if(res){
                axios.put(config.DEV_URL + "/trailingChanges", {
                    actionTaken: `Updated Asset: ${data.map(i => i.AssetName)}`,
                    actionLoc: "Asset Update Page",
                    changeMade: JSON.stringify(changesArr),
                    IPv4Addy: ipv4Addy,
                    userName: sessionStorage.getItem("user?name")
                }).catch(err => {
                    console.log(err);
                })
                //console.log(res);
                if(inUse == "true"){
                    axios.post(config.DEV_URL + "/deviceAssignUpdateBySerial", {
                        devID: data.map(list => list.SerialNumber),
                        isAssigned: true
                    }).then(res => {
                        //console.log(res);
                        window.location = "/AssetsList";
                    }).catch(err => {
                        console.log(err);
                    })
                }
                else{
                    axios.post(config.DEV_URL + "/deviceAssignUpdateBySerial", {
                        devID: data.map(list => list.SerialNumber),
                        isAssigned: false
                    }).then(res => {
                        window.location = "/AssetsList";
                    }).catch(err => {
                        console.log(err);
                    })
                }
                //window.location = "/AssetsList";
             }
             else{
                window.alert("Some Information Was Incorrect");
             }
        }).catch(err => {
            window.alert("Error");
        })
      }
        e.preventDefault();
    }
    
    
    return(
        <div>
 <div className="MainForms">
            <form onSubmit={e => handleSubmission(e)}>
            <label>User of Asset</label>
            <br></br>
            <label>Is Asset Currently In Use?</label>
            <br></br>
            <div className="formEffects">
            <select onChange={e => setInUse(e)}>
                <option value="#" selected disabled>Please Choose An Option</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
            </select>
            </div>
            <br></br>

            <br></br>
            <label>Asset Expiry Date(optional)</label>
            <br></br>
            <div className="formEffects">
            <input type="date" onChange={e => setDate(e)}></input>
            </div>
            <br></br>

            <label>Notes</label>
            <br></br>
            <div className="formEffects">
                <textarea onChange={e => setNotes(e)} maxLength={5000} defaultValue={data.map(list => list.AssetNotes)}></textarea>
            </div>
            <br></br>

            <p>{error}</p>
            <br></br>
            <button type="submit">Submit</button>
            </form>
        </div>
        </div>
    )
}

export default UpdateAsset;