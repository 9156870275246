import axios from "axios";
import { useEffect, useState } from "react";
import config from "../Settings.json";

const GetEmployeesReport = () => {
const[data, setData] = useState([]);
const[latestTime, setTime] = useState("");
useEffect(() =>{
    axios.put(config.DEV_URL + "/getEmployeesLimit", {
        Company: sessionStorage.getItem("getCompany"),

    }).then(res =>{
        setData(res.data);
        //console.log(res.data);
    }).catch(err =>{
        console.log(err);
    });
    getTime();
}, []);

function getTime(){
    setTime(new Date());
}

setTimeout(getTime, 1000);

const getSearchVal = (e) => {
    searchList(e);
    /*let onClickFunc = document.getElementById("searchVal");
    if(onClickFunc.addEventListener("keypress", function(c){
        if(c.key === "Enter"){
            searchList(e);
            c.stopImmediatePropagation();
        }
    }))*/
    e.preventDefault();
}

 const searchList = (e) => {
    axios.post(config.DEV_URL + "/getEmpSearchLimit", {
        Filt: e.target.value,
        Comp: sessionStorage.getItem("getCompany")
    }).then(res =>{
        setData(res.data);
        //console.log(res.data);
    }).catch(err =>{
        console.log(err);
    });
}

return(
    <div>
        
        
        <h2 className="pageTitle">Employees</h2>
        <div className="buttontoAdd"><button onClick={window.print}>Print</button>
        <span> 🔍 </span>
        <input type="search" id="searchVal" onChange={e => getSearchVal(e)}>
        </input></div>
        <div className="reportTime">
        <h3>Printed: {latestTime.toString().split("GMT")[0]}</h3>
        </div>


    <table className="ContentList">
    <thead>
        <tr>
        <th>Employee First Name</th>
        <th>Employee Last Name</th>
        <th>Department</th>
        <th>Situated Location</th>
        <th>Division</th>
        {sessionStorage.getItem("getCompany") == "Axia" ? <th>Company</th> : null}
        <th>Current Status</th>
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Added By</th> : null}    
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ?<th>Time Added</th> : null}
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ?<th>Last Update</th> : null}
        {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <th>Updated By</th> : null} 
        </tr>
        </thead>
    <tbody>


    {data.map(list => (
    <tr key={list.EmpID}>
    <td>{list.EmpFirstName}</td>
    <td>{list.EmpLastName}</td>
    <td>{list.EmpDepartment}</td>
    <td>{list.EmpLocation}</td>
    <td>{list.EmpDivision}</td>
    {sessionStorage.getItem("getCompany") == "Axia" ? <td>{list.EmpCompany}</td> : null}
    <td>{list.EmpStatus}</td>
    {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td>{list.AddedBy}</td> : null}
    {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td>{list.TimeAdded}</td> : null}
    {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td>
    {list.lastUpdate ? list.lastUpdate.split("T")[0] : "-" }</td> : null}
    {sessionStorage.getItem("getFlag") == 1 || sessionStorage.getItem("getFlag") == 0 ? <td>{list.UpdatedBy}</td> : null}
    </tr>
    ))}
    </tbody>
    </table>
    </div>
    )
}

export default GetEmployeesReport;