import axios from "axios";
import { useEffect, useState } from "react";
import config from "../Settings.json";
import deleteIcon from "../buttonIcons/folder-delete-icon.png";
import updateIcon from "../buttonIcons/folder-refresh-icon.png";
import { publicIpv4 } from "public-ip";
const GetCompanies = () => {
const[data, setData] = useState([]);
const[ipv4Addy, setIP] = useState("");

async function getIP(){
    await publicIpv4().then(res => {
        setIP(res);
    })
}
useEffect(() =>{
    getIP();
    axios.put(config.DEV_URL + "/getCompanies").then(res =>{
        setData(res.data);
    }).catch(err =>{
        console.log(err);
    });
    
}, []);

const handleDelete = async (e) =>{

    if(window.confirm("Do you want to delete Company ID: " + e.target.value) === true){
        var changesArr = {};

        await axios.put(config.DEV_URL + "/getCompaniesByID",{
            cID: e.target.value
        }).then(result => {
            changesArr = result.data;
        }).catch(err => {
            console.log(err);
        })

        axios.post(config.DEV_URL +"/DeleteCompany", {
        cID: e.target.value
        }).then(res => {
        if(res == null){
            console.log("Invalid ID");
        }
        else{
            axios.put(config.DEV_URL + "/trailingChanges", {
                actionTaken: "Deleted Company",
                actionLoc: "Company Listing",
                changeMade: JSON.stringify(changesArr),
                IPv4Addy: ipv4Addy,
                userName: sessionStorage.getItem("user?name")
            }).catch(err => {
                console.log(err);
            })
            window.location.reload(); 
            //console.log(res);
        }
    }).catch(err => {
        console.log(err);
    })
   }
  else{
    //console.log("aborted");
  }
}

const addCompany = () => {
    window.location = "/add-company";
}

const filterData = (e) =>{

    axios.put(config.DEV_URL + "/getCompanies", {
        Filter: e.target.value
    }).then(res =>{
        setData(res.data);
    }).catch(err =>{
        console.log(err);
    });
  }

  const updateCompany = (e) => {
        window.location = `/Update-Company/${e.target.value}`;
 }
 const getSearchVal = (e) => {
    searchList(e);
    e.preventDefault();
}

 const searchList = (e) => {
    axios.post(config.DEV_URL + "/getCompSearch", {
        Filt: e.target.value
    }).then(res =>{
        setData(res.data);
    }).catch(err =>{
        console.log(err);
    });
}

return(
    <div>
        <h2 className="pageTitle">Companies</h2>
        <div className="buttontoAdd">
        {sessionStorage.getItem("getFlag") == 0 ? <button onClick={() => addCompany()}>Add A Company</button> : null}
        <span> 🔍 </span>
        <input type="search" id="searchVal" onChange={e => getSearchVal(e)}>
        </input>
        </div>

        <select className="filterList" onClick={e => filterData(e)}>
            <option value={"ASC"} >Sort By Oldest</option>
            <option value={"DESC"} selected >Sort By Newest</option>
        </select>

    <table className="ContentList">
    <thead>
        <tr>
        <th>Company Name</th>
        <th>Address</th>
        <th>Address 2</th>
        <th>Town/City</th>
        <th>County</th>
        <th>Postcode</th>
        <th>Phone Number</th>
        <th>Time Added</th>
        <th>Last Update</th>
        <th>Delete Company</th>
        <th>Update Company</th>
        </tr>
        </thead>
    <tbody>


    {data.map(list => (
    <tr key={list.ComID}>
    <td>{list.CompanyName}</td>
    <td>{list.CompanyAddress ? list.CompanyAddress : "-"}</td>
    <td>{list.CompanyAddress2 ? list.CompanyAddress2 : "-"}</td>
    <td>{list.CompanyTown ? list.CompanyTown : "-"}</td>
    <td>{list.CompanyCounty ? list.CompanyCounty : "-"}</td>
    <td>{list.CompanyPost ? list.CompanyPost : "-"}</td>
    <td>{list.CompanyPhone ? <a href={`tel:${list.CompanyPhone}`}>{list.CompanyPhone}</a> : "-"}</td>
    <td>{list.TimeAdded.split("T")[0]}</td>
    <td> {list.LastUpdate ? list.LastUpdate.split("T")[0] : "-" }</td>
    <td><button value={list.ComID} className="tableButton" onClick={e => handleDelete(e)} title="Delete Company"><img src={deleteIcon}></img></button></td>
    <td><button value={list.ComID} className="tableButton" onClick={e => updateCompany(e)} title="Update Company"><img src={updateIcon}></img></button></td>
    </tr>
    ))}
    </tbody>
    </table>
    </div>
    )
}

export default GetCompanies;