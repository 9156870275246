import { useParams } from "react-router"
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../Settings.json";
import QRCode from "react-qr-code";
const VerifyAuth = () => {
    const token = useParams();
    const [qrValue, setQRValue] = useState("");
    const [messageVerify, setMSG] = useState(null);
    const [inputKey] = useState([]);
    useEffect(()=>{
        axios.get(config.DEV_URL + `/verifyToken/${token.sessToken}`).then(res => {
            if(res.data[1] == true){

                axios.post(config.DEV_URL + "/insertAuth", {
                    userID: sessionStorage.getItem("userIdentity?id"),
                    authKey: res.data[0].sessionPass,
                    authEnabled: true
                }).catch(errorAuth => {
                    console.log(errorAuth);
                })

                axios.post(config.DEV_URL + "/otp?", {
                    userName: sessionStorage.getItem("user?name"),
                    secretPass: res.data[0].sessionPass
                }).then(tokenRes => {
                    setQRValue(tokenRes.data);
                })
            }
            else{
                window.location = "/CurrentHardware";
            }
        }).catch(err => {
            console.log(err);
        })
    }, [])

    const getInputKey1 = (e) => {
        inputKey[1] = e.target.value;
        e.preventDefault();
    }
    const getInputKey2 = (e) => {
        inputKey[2] = e.target.value;
        e.preventDefault();
    }
    const getInputKey3 = (e) => {
        inputKey[3] = e.target.value;
        e.preventDefault();
    }
    const getInputKey4 = (e) => {
        inputKey[4] = e.target.value;
        e.preventDefault();
    }
    const getInputKey5 = (e) => {
        inputKey[5] = e.target.value;
        e.preventDefault();
    }
    const getInputKey6 = (e) => {
        inputKey[6] = e.target.value;
        e.preventDefault();
    }
    const checkValidation = (e) => {
        let key = "";
        for(let i = 1; i < inputKey.length; i++){
            key += inputKey[i];
        }
        axios.post(config.DEV_URL + "/getSecretKeyForUser", {
            userID: sessionStorage.getItem("userIdentity?id")
        }).then(res => {
            if(res){
                axios.post(config.DEV_URL + "/otpverify", {
                    userName: sessionStorage.getItem("user?name"),
                    secretPass: res.data,
                    userInput: key
                }).then(isVerified => {
                    console.log(isVerified);
                    if(isVerified.data == true){
                        setMSG("Verified Successfully");
                    }
                    else{
                        setMSG("Oops wrong code");
                    }
                }).catch(err => {
                    console.log(err);
                })
            }
        })
    }


    
    return(
        <>
        <div style={{textAlign: "center"}}><QRCode value={qrValue}/></div>
        <div>
        <h2 style={{textAlign: "center", fontSize: "20px", fontFamily: "Karla"}}>Insert code below to check if 2FA is now activated</h2>
        {messageVerify == null ? <br></br> : <h2 style={{textAlign: "center", fontSize: "20px", fontFamily: "Karla"}}>{messageVerify}</h2>}
        <div style={{textAlign:"center", position: "inherit", padding: "5px"}}>   
        <input type="text" style={{textAlign:"center", width: "20px", margin: "5px"}} maxLength={1} onChange={e => getInputKey1(e)}></input>
        <input type="text" style={{textAlign:"center", width: "20px", margin: "5px"}} maxLength={1} onChange={e => getInputKey2(e)}></input>
        <input type="text" style={{textAlign:"center", width: "20px", margin: "5px"}} maxLength={1} onChange={e => getInputKey3(e)}></input>
        <input type="text" style={{textAlign:"center", width: "20px", margin: "5px"}} maxLength={1} onChange={e => getInputKey4(e)}></input>
        <input type="text" style={{textAlign:"center", width: "20px", margin: "5px"}} maxLength={1} onChange={e => getInputKey5(e)}></input>
        <input type="text" style={{textAlign:"center", width: "20px", margin: "5px"}} maxLength={1} onChange={e => getInputKey6(e)}></input>
        </div>
        <div style={{textAlign: "center"}}><button style={{textAlign: "center"}} onClick={e => checkValidation(e)}>Check</button></div>
        </div>

        </>
    )
}

export default VerifyAuth;