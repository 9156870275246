import { useState, useEffect } from "react"
import axios from "axios";
import config from "../Settings.json";
import { useParams } from "react-router";
import { publicIpv4 } from "public-ip";
const UpdateCompany = () => {
   const id = useParams();
   const[data, setData] = useState([]);
   const[address ,setAddy] = useState("");
   const[address2 ,setAddy2] = useState("");
   const[postcode ,setPost] = useState("");
   const[phoneno, setNum] = useState("");
   const[town, setTown] = useState("");
   const[county, setCounty] = useState("");
   const[changesArr] = useState({});
   const[ipv4Addy, setIP] = useState("");

   async function getIP(){
      await publicIpv4().then(res => {
         setIP(res);
      })
   }
   useEffect(()=>{
      getIP();
      axios.put(config.DEV_URL + "/getCompaniesByID",{
         cID: id.cID
      }).then(res => {
         setData(res.data);
         setAddy(res.data.map(item => item.CompanyAddress));
         setAddy2(res.data.map(item => item.CompanyAddress2));
         setPost(res.data.map(item => item.CompanyPost));
         setNum(res.data.map(item => item.CompanyPhone));
         setTown(res.data.map(item => item.CompanyTown));
         setCounty(res.data.map(item => item.CompanyCounty));

      }).catch(err =>{
         console.log(err);
      })
    }, [])


    const getAddy = (e) => {
        setAddy(e.target.value);
        changesArr["Prev Address"] = data.map(item => item.CompanyAddress);
        changesArr["New Address"] = e.target.value;
        e.preventDefault(); 
     }

     const getAddy2 = (e) => {
        setAddy2(e.target.value);
        changesArr["Prev Address 2"] = data.map(item => item.CompanyAddress2);
        changesArr["New Address 2"] = e.target.value;
        e.preventDefault(); 
     }
     
     const getTown = (e) => {
      setTown(e.target.value);
      changesArr["Prev Town"] = data.map(item => item.CompanyTown);
      changesArr["New Town"] = e.target.value;
      e.preventDefault(); 
     }

     const getCounty = (e) => {
      setCounty(e.target.value);
      changesArr["Prev County"] = data.map(item => item.CompanyCounty);
      changesArr["New County"] = e.target.value;
      e.preventDefault(); 
     }

     const getPost = (e) => {
        setPost(e.target.value);
        changesArr["Prev Post-Code"] = data.map(item => item.CompanyPost);
        changesArr["New Post-Code"] = e.target.value;
        e.preventDefault(); 
     }

     const getPhone = (e) => {
        setNum(e.target.value);
        changesArr["Prev Phone"] = data.map(item => item.CompanyPhone);
        changesArr["New Phone"] = e.target.value;
        e.preventDefault(); 
     }
     const handleSubmission = (e) => {
        axios.put(config.DEV_URL +"/updateCompany", {
         cID: id.cID,
         cAddress: address,
         cAddress2: address2,
         cPost: postcode,
         cPhone: phoneno,
         cTown: town,
         cCounty: county,
         updatedBy: sessionStorage.getItem("user?name")
        }).then(res => {
         if(res != null){
            axios.put(config.DEV_URL + "/trailingChanges", {
               actionTaken: `Updated Company: ${data.map(i => i.CompanyName)}`,
               actionLoc: "Company Update Page",
               changeMade: JSON.stringify(changesArr),
               IPv4Addy: ipv4Addy,
               userName: sessionStorage.getItem("user?name")
           }).catch(err => {
               console.log(err);
           })
            window.location = "/Get-Companies";
         }
         else{
            window.alert("Entry Was not added, Re enter fields and try again");
         }
        }).catch(err => {
         console.log(err);
        })
        e.preventDefault();
     }

    return(
        <div>
         <div className="MainForms">
         <label className="formTitles">Update Company Details</label>
         <form onSubmit={e => handleSubmission(e)}>
            <br></br>
            <label>Company Name</label>
            <br></br>
            <div className="formEffects">
            <input type="text" disabled 
            placeholder={data.map(item => item.CompanyName)}></input>
            </div>
            <br></br>
            <label>Update Address Line 1</label>
            <br></br>
            <div className="formEffects">
            <input type="text" onChange={e=>getAddy(e)} 
            placeholder={data.map(item => item.CompanyAddress)}></input>
            </div>

            <br></br>
            <label>Update Address Line 2 </label>
            <br></br>
            <div className="formEffects">
            <input type="text" onChange={e=>getAddy2(e)}
            placeholder={data.map(item => item.CompanyAddress2)}></input>
            </div>

            <br></br>
            <label>Update Postcode </label>
            <br></br>
            <div className="formEffects">
            <input type="text" onChange={e=>getPost(e)}
            placeholder={data.map(item => item.CompanyPost)}></input>
            </div>

            <br></br>
            <label>Update Town/City </label>
            <br></br>
            <div className="formEffects">
            <input type="text" onChange={e=>getTown(e)}
            placeholder={data.map(item => item.CompanyTown)}></input>
            </div>

            <br></br>
            <label>Update County </label>
            <br></br>
            <div className="formEffects">
            <input type="text" onChange={e=>getCounty(e)}
            placeholder={data.map(item => item.CompanyCounty)}></input>
            </div>
            
            <br></br>
            <label>Update Phone Number </label>
            <br></br>
            <div className="formEffects">
            <input type="tel" minLength="10" maxLength="15"
            onChange={e=>getPhone(e)}
            placeholder={data.map(item => item.CompanyPhone)}></input>
            </div>

            <button type="submit">
               Submit
            </button>
            </form>
         </div>
        </div>
    )
}


export default UpdateCompany;