import JsBarcode from "jsbarcode";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import QRCode from "react-qr-code";
import "../../SASS/CSS Files/barcode.css";
const Barcode = () => {
    const id = useParams();
    const [isQR, setIsQR] = useState(false);
    useEffect(() => {
        
        if(id.Type == "barcode"){
            JsBarcode("#code", id.ID, {
                width: 1,
                height: 20,
                fontSize: 16,
                textAlign: "center",
            });
        }
        else if(id.Type == "qr"){
            setIsQR(true);
        }
        else {
            JsBarcode("#code", "Nothing", {
                width: 1,
                height: 20,
                fontSize: 16,
                textAlign: "center",
            });
        }
    }, [])

    return(
        <>
        <div className="barcodeClass" onLoad={window.print()}>
            <h1 style={{fontSize: 24, textAlign: "center", fontFamily: "Karla"}}>For Pool ID: {id.ID}</h1>
            <br></br>
            {isQR === true ? 
            <>
            <div className="codeCanvas">
            <p className="idPlacement">{id.ID}</p>
            <QRCode value={id.ID} style={{width: "40px", height: "70px"}}/>
            </div>
            <br></br>
            <div className="codeCanvas">
            <p className="idPlacement">{id.ID}</p>
            <QRCode value={id.ID} style={{width: "40px", height: "70px"}}/>
            </div> 
            </> :
            <>
            <div className={"codeCanvas"}>
                <img id="code"></img>
            </div>
            <br></br>
            <div className={"codeCanvas"}>
                <img id="code"></img>
            </div>
            </>
            }
        </div>
        </>
    )
}

export default Barcode;